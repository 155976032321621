<script lang="ts">
	import { languageStore } from '$lib/store/language.store';
	import { browser } from '$app/environment';
	import { shippingMarkets } from '$lib/utils/shippingCountries';
	import { getTranslate, T } from '@tolgee/svelte';
	import { currencyCodeToSymbol } from '$lib/utils/i18n';
	import { languageHelperStore, PickerState } from '$lib/store/languageHelper.store';
	import ButtonPrimary from '$lib/components/Button/ButtonPrimary.svelte';
	import { navBarSize } from '$lib/store/navBarSize.store';

	$: localeName =
		new Intl.DisplayNames($languageStore.locale, { type: 'region' }).of($languageStore.country) ??
		$languageStore.country;

	$: currentCurrency = shippingMarkets.find((market) =>
		market.regions.nodes.find((n) => n.code === $languageStore.country)
	)?.currencySettings.baseCurrency;

	const { t } = getTranslate();

	const onChange = () => {
		languageHelperStore.setPickerState(PickerState.CHOOSE_COUNTRY);
	};

	const onClose = () => {
		languageHelperStore.setPickerState(PickerState.DONE);
		languageStore.saveConfig();
	};
</script>

{#if $languageHelperStore.state === PickerState.INITIAL || $languageHelperStore.state === PickerState.FINISH}
	<div
		style:top={`${$navBarSize}px`}
		class="fixed mt-8 md:right-12 md:left-auto max-w-[95%] left-1/2 transform -translate-x-1/2 md:translate-x-0 bg-white rounded-md p-4 shadow-l3 z-40 flex"
	>
		<div class={`w-10 h-7 mr-2 i-flag-${$languageStore.country.toLowerCase()}`}></div>
		<div class="flex-1 w-80">
			<div class="text-h-16">
				{$t('shipping.country.destination.prefix')}
				{localeName}
			</div>
			<div class="text-p-14">
				{@html $t('shipping.country.description', { b: '<b />', country: localeName })}
				<ul class="list-disc my-2 ml-4">
					{@html $t('shipping.country.bullets', {
						li: '<li />',
						country: $t(`country.${$languageStore.country}`),
						currency: `${currentCurrency?.currencyCode} ${currencyCodeToSymbol(
							currentCurrency?.currencyCode ?? 'EUR'
						)}`
					})}
				</ul>
				<button on:click={() => onChange()} class="text-left">
					{@html $t('shipping.country.change-option-here', { u: '<u />' })}
				</button>
			</div>
			{#if $languageHelperStore.showAdvancedOptions}
				<ButtonPrimary
					on:click={onClose}
					text={$t('shipping.country.continue')}
					class="w-full mt-5 mb-3"
				/>
				<button
					on:click={() => onChange()}
					class="underline text-accent-primary w-full text-center"
				>
					{$t('shipping.country.change')}
				</button>
			{/if}
		</div>
		<button on:click={() => onClose()} class="w-4 h-4 i-sprenger-x"> </button>
	</div>
{/if}
