import { derived, writable } from 'svelte/store';

export type B2BSessionType = null | 'register' | 'login' | 'reset' | 'default';

const createB2BSessionDrawer = () => {
	const isExpanded = writable(false);
	const sessionType = writable<B2BSessionType>('default');

	const data = derived([isExpanded, sessionType], ([$isExpanded, $sessionType]) => {
		return {
			isExpanded: $isExpanded,
			sessionType: $sessionType
		};
	});

	return {
		...data,
		setIsExpanded: (value: boolean) => {
			isExpanded.set(value);
		},
		setSessionType: (value: B2BSessionType) => {
			sessionType.set(value);
		},
		toggle: () => {
			isExpanded.update((value) => !value);
		}
	};
};

export const b2bSessionDrawer = createB2BSessionDrawer();
