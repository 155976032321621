import { derived, writable } from 'svelte/store';
import { languageStore } from '$lib/store/language.store';

export enum PickerState {
	INITIAL,
	CHOOSE_COUNTRY,
	FINISH,
	DONE = -1
}

const createLanguageHelperStore = () => {
	const state = writable(PickerState.DONE);

	const reactive = derived([state], ([state]) => ({
		state,
		showAdvancedOptions: state === PickerState.INITIAL
	}));

	return {
		...reactive,
		setPickerState: (newState: PickerState) => {
			state.set(newState);
		},
		init: () => {
			if (languageStore.hasInteracted()) {
				state.set(PickerState.DONE);
			} else {
				state.set(PickerState.INITIAL);
			}
		}
	};
};

export const languageHelperStore = createLanguageHelperStore();
