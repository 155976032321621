<script>
	import RichText from '$lib/components/Storyblok/RichText.svelte';

	export let blok;
</script>

<div class="flex flex-col gap-10">
	<div class="flex flex-col gap-3">
		{#if blok?.title}
			<div class="text-h-40 text-[#929191] text-center lg:text-start hyphens-auto">
				{blok.title}
			</div>
		{/if}
		{#if blok?.jobDescription}
			<RichText text={blok.jobDescription} class="" />
		{/if}
	</div>
	<!-- CTA -->
	{#if blok?.ctaLink?.cached_url && blok?.ctaLabel}
		<a
			href={blok.ctaLink.email ? `mailto:${blok.ctaLink.email}` : blok.ctaLink.cached_url}
			target={blok?.ctaLink?.target || '_self'}
			class="py-3 px-4.5 text-h-16 bg-accent-primary text-white w-fit rounded-full"
		>
			{blok.ctaLabel}
		</a>
	{/if}

	{#if blok?.jobDescriptionPDF?.filename}
		<div class="flex flex-col lg:flex-row items-center bg-accent-primary">
			{#if blok?.jobDescriptionImage?.filename}
				<picture class="w-full lg:w-5/12 lg:h-full lg:aspect-[3/4] shrink-0">
					<img class="h-full w-full object-cover" src={blok.jobDescriptionImage.filename} alt="" />
				</picture>
			{/if}
			<div class="flex flex-col gap-8 py-12 px-5 lg:px-10">
				<div class="flex flex-col gap-3 text-white">
					<span class="text-2xl">Download</span>
					<p class="text-xl font-light">
						Lade die Stellenbeschreibung runter und schau Dir die passenden Stellen zu Deinen Skills
						und Deiner Erfahrung an.
					</p>
				</div>
				<a
					class="flex items-center justify-center rounded-full py-3 text-accent-primary text-h-16 bg-white"
					href={blok.jobDescriptionPDF.filename}
					target="_blank"
				>
					Jetzt herunterladen
				</a>
			</div>
		</div>
	{/if}
</div>
