<script lang="ts">
	import type { LayoutData } from './$types';
	import { TolgeeProvider, Tolgee, DevTools, FormatSimple } from '@tolgee/svelte';
	import '@unocss/reset/tailwind.css';
	import 'virtual:uno.css';
	import '$lib/styles/global.css';
	import NavigationBar from '$lib/components/NavBar/NavigationBar.svelte';
	import CollectionSideDrawer from '$lib/components/Drawer/CollectionSideDrawer.svelte';
	import CustomerSessionDrawer from '$lib/components/Drawer/CustomerSessionDrawer.svelte';
	import { page } from '$app/stores';
	import { userCentricsHelper } from '$lib/store/userCentricsHelper.store';
	import { onMount } from 'svelte';
	import { cart } from '$lib/store/cart.store';
	import { customerSessionStore } from '$lib/store/customerSession.store';
	import NotificationHandler from '$lib/components/NotificationHandler.svelte';
	import { languageHelperStore } from '$lib/store/languageHelper.store';
	import InfoPopup from '$lib/components/Language/InfoPopup.svelte';
	import CountryChooser from '$lib/components/Language/CountryChooser.svelte';
	import Footer from '$lib/components/Footer.svelte';
	import ScrollToTop from '$lib/components/ScrollToTop.svelte';
	import GlobalProductAddedToCartDrawer from '$lib/components/Drawer/GlobalProductAddedToCartDrawer.svelte';
	import { LOCALE_COUNTRY_PRERENDER_MAPPING } from '$lib/i18n/constants';
	import { resolveRoute } from '$app/paths';
	import posthog from 'posthog-js';
	import { browser } from '$app/environment';
	import { beforeNavigate } from '$app/navigation';
	import { isProd } from '$lib/utils';
	import { tripleTrack } from '$lib/whale';
	import B2BSessionDrawer from '$lib/components/Drawer/B2BSessionDrawer.svelte';
	import { canonical } from '$lib/store/canonical.store';

	export let data: LayoutData;

	const tolgee = Tolgee()
		.use(DevTools())
		.use(FormatSimple())
		.init({
			language: $page.params.locale,
			// for development
			apiUrl: import.meta.env.VITE_TOLGEE_API_URL,
			apiKey: import.meta.env.VITE_TOLGEE_API_KEY,
			staticData: data.staticData
		});

	const tagManagerConsent = userCentricsHelper.hasConsent('Google Tag Manager');
	const hasChosen = userCentricsHelper.hasChosen;

	$: if ($page.params.locale !== tolgee.getLanguage()) {
		tolgee.addStaticData(data.staticData);
		tolgee.changeLanguage($page.params.locale);
	}

	let loadZe = false;
	let fixZIndexTimer: NodeJS.Timeout;

	let maxScrollPixels = 0;
	let maxScrollPercentage = 0;

	function handleScroll() {
		const lastPercentage = Math.min(
			1,
			(window.innerHeight + window.pageYOffset) / document.body.offsetHeight
		);
		const lastPixels = window.innerHeight + window.pageYOffset;
		if (lastPercentage > maxScrollPercentage) {
			maxScrollPercentage = lastPercentage;
		}
		if (lastPixels > maxScrollPixels) {
			maxScrollPixels = lastPixels;
		}
	}

	const fixZIndexBubble = () => {
		if (fixZIndexTimer) {
			clearTimeout(fixZIndexTimer);
		}
		fixZIndexTimer = setTimeout(() => {
			const launcher = document.getElementById('launcher');
			if (launcher) {
				launcher.style.zIndex = '40';
			} else {
				fixZIndexBubble();
			}
		}, 5000);
	};

	onMount(() => {
		cart.init(tolgee.t);
		customerSessionStore.init();
		languageHelperStore.init();
		fixZIndexBubble();
		setTimeout(() => {
			loadZe = true;
		}, 5000);
		posthog.init('phc_kYKgioAfTWPsXSEOsgn2tZFCBbTeRjnDRLonGtFVpYj', {
			api_host: 'https://eu.posthog.com',
			capture_pageleave: false
		});
	});

	beforeNavigate(() => {
		posthog.capture('$pageleave', {
			'max scroll percentage': maxScrollPercentage,
			'max scroll pixels': maxScrollPixels,
			'last scroll percentage': Math.min(
				1,
				(window.innerHeight + window.pageYOffset) / document.body.offsetHeight
			),
			'last scroll pixels': window.innerHeight + window.pageYOffset,
			scrolled: maxScrollPixels > 0
		});
	});

	$: if (browser && $hasChosen) {
		if ($tagManagerConsent) {
			posthog.opt_in_capturing();
			posthog.startSessionRecording();
		} else {
			tripleTrack('gdpr');
			posthog.opt_out_capturing();
			posthog.stopSessionRecording();
		}
	}

	const onZeLoaded = () => {
		try {
			window.zE('webWidget', 'setLocale', $page.params.locale);
		} catch {
			// ignore
		}
	};
</script>

<svelte:window on:scroll={() => handleScroll()} />

<svelte:head>
	<link rel="preconnect dns-prefetch" href="https://api.config-security.com/" crossorigin />
	<link rel="preconnect dns-prefetch" href="https://conf.config-security.com/" crossorigin />
	<link rel="preconnect dns-prefetch" href="https://whale.camera/" crossorigin />
	<script
		id="usercentrics-cmp"
		data-language={$page.params.locale}
		src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
		data-settings-id="dS5F08OyI"
		defer
	></script>
	{#if loadZe}
		<script
			id="ze-snippet"
			type="application/javascript"
			src="https://static.zdassets.com/ekr/snippet.js?key=3823b184-333a-407a-a4aa-30066c650596"
			defer
			on:load={onZeLoaded}
		></script>
	{/if}
	<script>
		/* >> TriplePixel :: start*/
		(window.TriplePixelData = {
			TripleName: 'hs-sprenger.myshopify.com',
			ver: '2.12',
			plat: 'SHOPIFY',
			isHeadless: true
		}),
			(function (W, H, A, L, E, _, B, N) {
				function O(U, T, P, H, R) {
					void 0 === R && (R = !1),
						(H = new XMLHttpRequest()),
						P
							? (H.open('POST', U, !0), H.setRequestHeader('Content-Type', 'text/plain'))
							: H.open('GET', U, !0),
						H.send(JSON.stringify(P || {})),
						(H.onreadystatechange = function () {
							4 === H.readyState && 200 === H.status
								? ((R = H.responseText), U.includes('.txt') ? eval(R) : P || (N[B] = R))
								: (299 < H.status || H.status < 200) && T && !R && ((R = !0), O(U, T - 1, P));
						});
				}
				if (((N = window), !N[H + 'sn'])) {
					(N[H + 'sn'] = 1),
						(L = function () {
							return Date.now().toString(36) + '_' + Math.random().toString(36);
						});
					try {
						A.setItem(H, 1 + (0 | A.getItem(H) || 0)),
							(E = JSON.parse(A.getItem(H + 'U') || '[]')).push({
								u: location.href,
								r: document.referrer,
								t: Date.now(),
								id: L()
							}),
							A.setItem(H + 'U', JSON.stringify(E));
					} catch (e) {}
					var i, m, p;
					A.getItem('"!nC`') ||
						((_ = A),
						(A = N),
						A[H] ||
							((E = A[H] =
								function (t, e, a) {
									return (
										void 0 === a && (a = []),
										'State' == t
											? E.s
											: ((W = L()), (E._q = E._q || []).push([W, t, e].concat(a)), W)
									);
								}),
							(E.s = 'Installed'),
							(E._q = []),
							(E.ch = W),
							(B = 'configSecurityConfModel'),
							(N[B] = 1),
							O('https://conf.config-security.com/model', 5),
							(i = L()),
							(m = A[atob('c2NyZWVu')]),
							_.setItem('di_pmt_wt', i),
							(p = {
								id: i,
								action: 'profile',
								avatar: _.getItem('auth-security_rand_salt_'),
								time: m[atob('d2lkdGg=')] + ':' + m[atob('aGVpZ2h0')],
								host: A.TriplePixelData.TripleName,
								plat: A.TriplePixelData.plat,
								url: window.location.href,
								ref: document.referrer,
								ver: A.TriplePixelData.ver
							}),
							O('https://api.config-security.com/event', 5, p),
							O('https://whale.camera/live/dot.txt', 5)));
				}
			})('', 'TriplePixel', localStorage);
		/* << TriplePixel :: end*/
	</script>

	{#if $tagManagerConsent}
		{#if isProd()}
			<script>
				(function (w, d, s, l, i) {
					w[l] = w[l] || [];
					w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
					var f = d.getElementsByTagName(s)[0],
						j = d.createElement(s),
						dl = l != 'dataLayer' ? '&l=' + l : '';
					j.async = true;
					j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
					f.parentNode.insertBefore(j, f);
				})(window, document, 'script', 'dataLayer', 'GTM-N52VL68');
			</script>
		{:else}
			<script>
				(function (w, d, s, l, i) {
					w[l] = w[l] || [];
					w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
					var f = d.getElementsByTagName(s)[0],
						j = d.createElement(s),
						dl = l != 'dataLayer' ? '&l=' + l : '';
					j.async = true;
					j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
					f.parentNode.insertBefore(j, f);
				})(window, document, 'script', 'dataLayer', 'GTM-5F6FD67');
			</script>
		{/if}
	{/if}

	{#each $canonical as canonicalEntry}
		<link {...canonicalEntry} />
	{/each}
</svelte:head>
{#if $tagManagerConsent}
	<noscript
		><iframe
			src="https://www.googletagmanager.com/ns.html?id=GTM-N52VL68"
			height="0"
			width="0"
			style="display:none;visibility:hidden"
			title="Google Tag Manager"
		></iframe></noscript
	>
{/if}
<TolgeeProvider {tolgee}>
	<NavigationBar />
	<div class="relative">
		<slot />
	</div>
	<Footer />
	<div slot="fallback">Prerendering failed</div>
	<CollectionSideDrawer />
	<CustomerSessionDrawer />
	<B2BSessionDrawer />
	<NotificationHandler />
	<InfoPopup />
	<CountryChooser />
	<ScrollToTop />
	<GlobalProductAddedToCartDrawer />
</TolgeeProvider>
