<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { primaryColorDict, signatureDict } from '$lib/color';
	import { storyblokEditable } from '@storyblok/svelte';
	import type {
		ContentBranch,
		ContentBranchCore,
		ImageStoryblok,
		ShopTheLookProps
	} from '$lib/storyblok/types';
	import StoryblokImageOrVideo from '$lib/components/Image/StoryblokImageOrVideo.svelte';
	import { createBreakpointStore } from '$lib/store/breakpoint.store';
	import { getImagePriorityBelowFold } from '$lib/utils/image';

	interface EditorialSectionEightProps {
		title: string;
		image?: ImageStoryblok;
		content_branch: ContentBranch;
		logo: ContentBranchCore;
		parallax?: boolean;
		layout: 'left' | 'right';
		shopTheLook?: ShopTheLookProps;
	}

	export let blok: EditorialSectionEightProps;
	export let position: number = 0;
	$: isBelowFold = position > 0;

	$: title = blok.title;
	$: image = blok.image;
	$: content_branch = blok.content_branch;
	$: logo = blok.logo;
	$: parallax = blok.parallax;
	$: layout = blok.layout;
	$: shopTheLook = blok.shopTheLook;

	const mobileStore = createBreakpointStore();
	$: isMobileViewport = $mobileStore;
</script>

<div
	use:storyblokEditable={blok}
	class="flex flex-row sm:flex-col items-center justify-center py-11 md:py-16"
>
	<div
		class={classes(
			'flex col-end md:row-end sm:min-w-fit md:min-w-full bg-dark-black w-full sm:w-auto',
			layout !== 'left' ? 'sm:flex-row flex-col' : 'sm:flex-row-reverse flex-col-reverse'
		)}
	>
		<div
			style="background-color: {primaryColorDict[content_branch] || primaryColorDict.dog}"
			class="flex flex-col justify-center items-center pl-1 pr-1 sm:pl-11 sm:pr-11 min-w-[100%] sm:min-w-[35%] overflow-x-hidden relative"
		>
			<div
				class="text-h-56 sm:text-h-72 text-center text-white mt-9 sm:mt-20 w-[22rem] tracking-wide"
			>
				{title}
			</div>
			<div class="mt-10 md:mt-44 w-44 bottom-8 mb-12">
				{#if signatureDict[logo]}
					<div class={classes(signatureDict[logo], 'w-full h-42')}></div>
				{/if}
			</div>
		</div>
		{#if image?.filename || shopTheLook}
			<div class={'relative md:float-right md:ml-auto w-full aspect-square sm:aspect-auto'}>
				<StoryblokImageOrVideo
					filename={image?.filename}
					placeholder="blur"
					alt={image?.alt}
					focus={image?.focus}
					{parallax}
					sizes={isMobileViewport ? '100vw' : '55vw'}
					{shopTheLook}
					class="object-cover h-full absolute w-full"
					title={image?.title}
					{...getImagePriorityBelowFold(isBelowFold)}
				/>
				<!-- //blurDataURL={withBlur(image?.filename)} -->
			</div>
		{/if}
	</div>
</div>
