<script lang="ts">
	import { getTranslate } from '@tolgee/svelte';

	const { t } = getTranslate();

	$: links = [
		{
			label: $t('customerSession.drawer.b2b.links.equestrianSports'),
			href: 'https://pferdesport.sprenger.de/account/login'
		},
		{
			label: $t('customerSession.drawer.b2b.links.boatSports'),
			href: 'https://bootsport.sprenger.de/account/login'
		},
		{
			label: $t('customerSession.drawer.b2b.links.dogSports'),
			href: 'https://hundesport.sprenger.de/account/login'
		}
	];
</script>

<div class="flex flex-col w-full pb-5 pt-16">
	<div class="py-2 text-white text-h-24">{$t('customerSession.drawer.b2b.button')}</div>
	<hr class="my-5" />
	<div class="flex flex-col gap-2">
		{#each links as link (link.label)}
			<div class="flex flex-row items-center">
				<div class="text-white text-h-14">{link.label}</div>
				<a
					target="_blank"
					href={link.href}
					class="flex items-center w-10 h-10 shrink-0 ml-auto md:mr-[5.125rem] border-2 border-transparent bg-white text-accent-primary hover:bg-accent-primary hover:text-white hover:border-white rounded-full justify-centers"
				>
					<div class="i-sprenger-chevron-right flex-1 w-5 h-5 shrink-0" />
				</a>
			</div>
		{/each}
	</div>
</div>
