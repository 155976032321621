import type { LayoutLoad } from './$types';
import { PUBLIC_STORYBLOK_ACCESS_TOKEN_PREVIEW } from '$env/static/public';
import { canonical } from '$lib/store/canonical.store';

import { StoryblokComponents } from '$lib/storyblok/components';
import { apiPlugin, storyblokInit } from '@storyblok/svelte';
import { resolveRoute } from '$app/paths';
import { LOCALE_COUNTRY_PRERENDER_MAPPING } from '$lib/i18n/constants';

export const load = (async (context) => {
	storyblokInit({
		accessToken: PUBLIC_STORYBLOK_ACCESS_TOKEN_PREVIEW,
		use: [apiPlugin],
		components: StoryblokComponents,
		apiOptions: {
			cache: {
				type: 'memory'
			}
		}
	});

	canonical.set([
		{
			rel: 'canonical',
			href: `https://www.sprenger.de${resolveRoute(context.route.id || '', {
				...context.params,
				locale: 'de',
				country: 'DE'
			}).replace(/^[./]*\./, '')}`
		},
		...LOCALE_COUNTRY_PRERENDER_MAPPING.filter(
			({ locale, country }) => `${locale}-${country}` !== `de-DE`
		).map(({ locale, country }) => ({
			rel: 'alternate' as const,
			hreflang: `${locale}-${country}`,
			href: `https://www.sprenger.de/${resolveRoute(context.route.id || '', {
				...context.params,
				locale,
				country
			}).replace(/^[./]*/, '')}`
		}))
	]);

	return { ...context.data, canonical };
}) satisfies LayoutLoad;
