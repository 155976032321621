<script lang="ts">
	import { storyblokEditable } from '@storyblok/svelte';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import StoryblokButton from '$lib/components/Button/StoryblokButton.svelte';
	import StoryblokImageOrVideo from '$lib/components/Image/StoryblokImageOrVideo.svelte';
	import Image from '$lib/components/Image/Image.svelte';
	import { type ContentBlock2Props, getImageDimensions } from '$lib/storyblok/types';
	import { createBreakpointStore } from '$lib/store/breakpoint.store';
	import RichText from '$lib/components/Storyblok/RichText.svelte';
	import { getImagePriorityBelowFold } from '$lib/utils/image';

	export let blok: ContentBlock2Props;
	export let position: number = 0;
	$: isBelowFold = position > 0;

	$: ({
		image_emphasis,
		video_id,
		autoplay,
		bg_image,
		floating_image,
		subtitle,
		title,
		description,
		button_link,
		button_text
	} = blok);

	const mobileStore = createBreakpointStore();
	$: isMobileViewport = $mobileStore;
</script>

<div
	use:storyblokEditable={blok}
	class={classes('py-11 md:py-16', blok.blue_background ? 'bg-accent-primary_005' : '')}
>
	<div class="flex flex-col space-y-6 space-y lg:space-y-16 box-x-wrapper">
		<div
			class={classes(
				'flex w-full md:gap-x-3 lg:gap-x-4',
				image_emphasis === 'left' ? 'flex-row-reverse' : 'flex-row'
			)}
		>
			{#if bg_image?.filename || video_id || blok.shopTheLook}
				<div class="relative w-full aspect-[1/1] lg:aspect-auto md:w-3/4 lg:min-h-[41rem]">
					<!-- // blurDataURL={withBlur(bg_image.filename)} -->
					<StoryblokImageOrVideo
						shopTheLook={blok.shopTheLook}
						filename={bg_image?.filename}
						placeholder="blur"
						alt={bg_image?.alt}
						focus={bg_image?.focus}
						sizes={isMobileViewport ? '100vw' : '75vw'}
						parallax={blok.image_parallax}
						{video_id}
						{autoplay}
						class="absolute w-full h-full object-cover"
						title={bg_image?.title}
						{...getImagePriorityBelowFold(isBelowFold)}
					/>
				</div>
			{/if}
			<div class="flex-col justify-between w-1/6 space-y-8 md:w-1/4 hidden md:flex">
				{#if floating_image?.filename}
					<div class="relative flex-0 p-3 md:p-4 lg:p-10 bg-radial-gradient-content-block">
						<div
							class={classes(
								'relative w-full h-full',
								image_emphasis === 'right' ? 'float-right' : 'float-left'
							)}
							style:aspect-ratio={`${getImageDimensions(floating_image)?.width} / ${
								getImageDimensions(floating_image)?.height
							}`}
							style:width={`${50 + (blok.desktopImageSize?.value ?? 100)}%`}
						>
							<div
								class={classes(
									'absolute w-[110%]',
									image_emphasis === 'right' ? 'right-0' : 'left-0'
								)}
							>
								<Image
									style={`aspect-ratio: ${getImageDimensions(floating_image)?.width} / ${
										getImageDimensions(floating_image)?.height
									}`}
									src={floating_image?.filename}
									alt={floating_image?.alt ?? ''}
									width={getImageDimensions(floating_image)?.width}
									height={getImageDimensions(floating_image)?.height}
									title={floating_image?.title}
									{...getImagePriorityBelowFold(isBelowFold)}
								/>
							</div>
						</div>
					</div>
				{/if}
				<div class="justify-center flex-1 lg:flex-0">
					<div class="flex-col">
						{#if subtitle}
							<span class="text-h-16">{subtitle}</span>
						{/if}
						{#if title}
							<h4 class="text-h-24 lg:text-h-32 mb-6 hyphens-auto">{title}</h4>
						{/if}
						<RichText text={description} />
						<div class="flex flex-col w-full items-stretch">
							{#if button_link && button_text}
								<StoryblokButton text={button_text} url={button_link.url} class="w-full my-6" />
							{/if}
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="flex flex-col justify-center md:hidden flex-0">
			{#if subtitle}
				<span class="text-h-16">{subtitle}</span>
			{/if}
			{#if blok.title}
				<span class="mb-2 text-h-24 lg:text-h-32 hyphens-auto">{title}</span>
			{/if}
			<RichText text={description} />
			<div class="flex flex-col w-full items-stretch">
				{#if button_link && button_text}
					<StoryblokButton text={button_text} url={button_link.url} class="w-full my-6" />
				{/if}
			</div>
		</div>
	</div>
</div>
