<script lang="ts">
	import { storyblokEditable } from '@storyblok/svelte';
	import { primaryColorDict } from '$lib/color';

	import { classes } from '@thearc-hq/ui-kit/utils';
	import type { ContentBlock4Props } from '$lib/storyblok/types';
	import StoryblokImageOrVideo from '$lib/components/Image/StoryblokImageOrVideo.svelte';
	import RichText from '$lib/components/Storyblok/RichText.svelte';
	import { getImagePriorityBelowFold } from '$lib/utils/image';

	export let blok: ContentBlock4Props;
	export let position: number = 0;
	$: isBelowFold = position > 0;
</script>

<div
	use:storyblokEditable={blok}
	class={classes(
		'w-full overflow-visible py-11 md:py-16',
		blok.blue_background ? 'bg-accent-primary_005' : ''
	)}
>
	<div class="space-y-6 lg:space-y-8 box-x-wrapper">
		{#if blok.image?.filename || blok.video_id || blok.shopTheLook}
			<div
				style:border-top-color={blok.content_branch === 'noBranding'
					? ''
					: primaryColorDict[blok.content_branch] || primaryColorDict.sprenger}
				class={`relative w-full aspect-video ${
					blok.content_branch === 'noBranding' ? '' : 'border-t-[16px]'
				} box-x-wrapper-half`}
			>
				<StoryblokImageOrVideo
					shopTheLook={blok.shopTheLook}
					filename={blok.image?.filename}
					focus={blok.image?.focus}
					placeholder="blur"
					alt={blok.image?.alt ?? ''}
					parallax={blok.image_parallax}
					sizes="75vw"
					video_id={blok.video_id}
					autoplay={blok.autoplay}
					class="absolute w-full h-full object-cover"
					title={blok.image?.title}
					{...getImagePriorityBelowFold(isBelowFold)}
				/>
				<!-- blurDataURL={withBlur(blok.image?.filename)} -->
			</div>
		{/if}
		<div
			data-test="__storyblok-content-block-4"
			class={classes('mx-auto space-y-4 md:pl-1.5', blok.is_text_wide ? 'w-full' : 'w-2/3')}
		>
			<div class="flex flex-col">
				<span class="text-h-16">{blok.subtitle}</span>
				{#if blok.title}
					<span class="text-h-32">{blok.title}</span>
				{/if}
			</div>
			<RichText text={blok.description} />
		</div>
	</div>
</div>
);
