<script lang="ts">
	import { customerSessionDrawer } from '$lib/store/customerSessionDrawer.store';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { getTranslate } from '@tolgee/svelte';
	import CustomerSessionDrawerHeader from '$lib/components/Drawer/CustomerSessionDrawerHeader.svelte';
	import LoginDrawer from '$lib/components/Drawer/Session/LoginDrawer.svelte';
	import { page } from '$app/stores';
	import { resolveRoute } from '$app/paths';
	import SignUpDrawer from '$lib/components/Drawer/Session/SignUpDrawer.svelte';
	import ForgotPassword from '$lib/components/Drawer/Session/ForgotPassword.svelte';

	const { t } = getTranslate();
</script>

{#if $customerSessionDrawer.isExpanded}
	<div
		on:click={() => customerSessionDrawer.setIsExpanded(false)}
		on:keydown={(e) => {
			if (e.key === 'Escape') {
				customerSessionDrawer.setIsExpanded(false);
			}
		}}
		role="button"
		tabindex="0"
		class="absolute left-0 top-0 w-full h-full"
	></div>
{/if}
<div
	class={classes(
		'fixed right-0 top-0 transform transition duration-500 md:min-w-sm md:max-w-sm w-full overflow-y-auto bg-white h-full z-50',
		$customerSessionDrawer.isExpanded
			? ''
			: 'md:translate-x-full translate-y-full md:translate-y-0 opacity-0'
	)}
>
	<div class="bg-accent-primary">
		<div class="p-4">
			<div class="flex justify-between">
				<button
					on:click={() => customerSessionDrawer.setSessionType('default')}
					class={classes(
						'i-sprenger-chevron-left w-6 h-6 bg-white transition duration-500',
						$customerSessionDrawer.sessionType !== 'default'
							? 'opacity-100'
							: 'opacity-0 pointer-events-none'
					)}
				></button>
				<button
					on:click={() => customerSessionDrawer.setIsExpanded(false)}
					class="i-sprenger-x w-6 h-6 bg-white"
				></button>
			</div>
			<CustomerSessionDrawerHeader />
		</div>
	</div>

	<div class="p-6">
		{#if $customerSessionDrawer.sessionType === 'login'}
			<LoginDrawer />
		{:else if $customerSessionDrawer.sessionType === 'reset'}
			<ForgotPassword />
		{:else if $customerSessionDrawer.sessionType === 'register'}
			<SignUpDrawer />
		{:else}
			<a
				href={resolveRoute('/[locale]-[country]/customer/orders', {
					...$page.params
				})}
			>
				<h5>
					{$t('orders.headline')}
				</h5>
			</a>
			<a
				href={resolveRoute('/[locale]-[country]/customer', {
					...$page.params
				})}
			>
				<h5>
					{$t('customer.profile.title')}
				</h5>
			</a>
		{/if}
	</div>
</div>
