<script lang="ts">
	import { NOTIFICATION, notificationStore } from '$lib/store/notification.store';
	import { classes } from '@thearc-hq/ui-kit/utils';

	const iconMap = {
		[NOTIFICATION.ALERT]: 'i-sprenger-alert-circle text-special-warn',
		[NOTIFICATION.ERROR]: 'i-sprenger-alert-circle text-special-danger',
		[NOTIFICATION.SUCCESS]: 'i-sprenger-check text-special-success'
	};
</script>

<div class="fixed top-0 right-0 z-50 flex flex-col w-full max-w-md">
	{#each $notificationStore as notification}
		<div class={classes('fixed top-0 z-50 w-full max-w-md p-3 mx-auto', 'relative p-2')}>
			<div class="flex p-4 space-x-2 rounded-lg bg-white shadow-l1">
				<div class={classes('flex-shrink-0 w-6 h-6', iconMap[notification.variant])}></div>

				<div class="flex-1 space-y-2 flex flex-col">
					<div class="space-y-1">
						<div class="text-h-16 text-accent-primary">{notification.title}</div>
						<div class="text-p-14 text-dark-secondary/75">{notification.text}</div>
					</div>

					{#if notification.actions && notification.actions.length > 0}
						<div class="flex space-x-3 flex-1 text-h-14 text-accent-primary">
							{#each notification.actions as action, index}
								{#if action.href}
									<a href={action.href}>
										{action.text}
									</a>
								{:else}
									<button>
										{action.text}
									</button>
								{/if}
							{/each}
						</div>
					{/if}
				</div>

				<button
					class="flex-shrink-0 w-4 h-4 text-accent-primary i-sprenger-x"
					on:click={() => notificationStore.removeNotification(notification.id)}
				>
				</button>
			</div>
		</div>
	{/each}
</div>
