<script lang="ts">
	import { T } from '@tolgee/svelte';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { storyblokEditable } from '@storyblok/svelte';
	import { clickOutside } from '$lib/utils/clickOutside';
	import { hasRichText } from '$lib/utils/richText';
	import slug from 'slug';

	import type { Writable } from 'svelte/store';
	import RichText from '$lib/components/Storyblok/RichText.svelte';

	export let content;
	export let hasPopup = false;
	export let index: number;
	export let totalCards: number;
	export let updateCurrentIndex: (nextIndex: number) => void;
	export let currentIndex: Writable<number>;
	export let hasPopupAtIndex: (index: number) => boolean;

	let dialog: HTMLDialogElement | null = null;

	const navigate = (direction: 'next' | 'prev') => {
		let newIndex = $currentIndex + (direction === 'next' ? 1 : -1);
		while (newIndex >= 0 && newIndex < totalCards && !hasPopupAtIndex(newIndex)) {
			newIndex += direction === 'next' ? 1 : -1;
		}
		if (newIndex >= 0 && newIndex < totalCards) {
			updateCurrentIndex(newIndex);
		}
	};

	const openPopup = (): void => {
		if (dialog) {
			currentIndex.set(index);
			dialog.showModal();
		}
	};
	const closePopup = (): void => {
		if (dialog) {
			dialog.close();
		}
	};

	$: if ($currentIndex === index && hasPopup) {
		openPopup();
	} else {
		closePopup();
	}

	$: ({ year, title, description, image } = content || {});
</script>

{#if year && hasRichText(description)}
	<li
		id={slug(year)}
		class="flex flex-col gap-5 border-r border-r-black px-9 first:pl-0 last:pr-0 w-[calc(100vw-12px-36px*2)] lg:w-106 last:border-r-none shrink-0 snap-center group"
		use:storyblokEditable={content}
	>
		<svelte:element
			this={hasPopup ? 'button' : 'div'}
			class="text-start flex flex-col gap-5"
			on:click={hasPopup ? openPopup : undefined}
			role={hasPopup ? 'button' : undefined}
		>
			{#if year}
				<h3 class="whitespace-nowrap text-h-40 lg:text-h-56 !font-semibold text-accent-primary">
					{year}
				</h3>
			{/if}
			{#if image?.filename}
				<picture class="aspect-square h-auto w-full">
					<img class="w-full h-full object-cover" src={image.filename} alt="" />
				</picture>
			{/if}
			{#if title}
				<p
					class={classes(
						'text-xl font-medium line-clamp-24',
						hasPopup
							? 'group-hover:text-accent-primary group-hover:underline transition-all duration-300 ease-in-out'
							: ''
					)}
				>
					{title}
				</p>
			{/if}
			{#if hasRichText(description)}
				<div
					class={classes(
						hasPopup ? 'transparent-mask' : '',
						hasPopup && image?.filename ? 'line-clamp-3' : '',
						!image?.filename ? 'line-clamp-16' : ''
					)}
				>
					<RichText text={description} class="text-base lg:text-xl font-light" />
				</div>
			{/if}
		</svelte:element>
		{#if hasPopup}
			<button
				class="text-left uppercase text-base font-extrabold group-hover:text-accent-primary group-hover:underline transition-all duration-300 ease-in-out"
				on:click={openPopup}
			>
				<T keyName="historyBlock.historyCard.popup.button" />
			</button>
			<dialog
				bind:this={dialog}
				class="mr-0 left-100% h-full w-full max-w-128 max-h-full translate-x-full flex fixed inset-0 z-100 transition-all ease-in-out duration-500"
			>
				<div class="relative flex flex-col bg-white" use:clickOutside on:click_outside={closePopup}>
					<div class="p-5 self-end">
						<button class="rounded-full" on:click={closePopup}>
							<div class="i-sprenger-x-circle h-10 w-10 text-accent-primary" />
						</button>
					</div>
					<div class="flex flex-col gap-6 lg:gap-21 pb-11 lg:pb-8">
						<div class="flex flex-col gap-10 px-3 lg:pl-5 lg:pr-20">
							{#if year}
								<h3 class="whitespace-nowrap text-h-40 !font-semibold">{year}</h3>
							{/if}
							{#if image?.filename}
								<picture class="h-auto w-full">
									<img class="w-full h-full object-cover" src={image.filename} alt="" />
								</picture>
							{/if}
							{#if description}
								<div class="text-xl font-light">
									<RichText text={description} />
								</div>
							{/if}
						</div>
						<div class="flex items-center self-center gap-3">
							<!-- Previous -->
							<button
								class="bg-accent-primary p-0.5 rounded-full disabled:opacity-50 disabled:cursor-default"
								on:click={() => navigate('prev')}
								disabled={$currentIndex === 1}
							>
								<div class="h-5 w-5 i-sprenger-chevron-left text-white" />
							</button>
							<button
								class="bg-accent-primary p-0.5 rounded-full disabled:opacity-50 disabled:cursor-default"
								on:click={() => navigate('next')}
								disabled={$currentIndex === totalCards - 1}
							>
								<div class="h-5 w-5 i-sprenger-chevron-right text-white" />
							</button>
						</div>
					</div>
				</div>
			</dialog>
		{/if}
	</li>
{/if}

<style>
	.transparent-mask {
		-webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
		mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
	}
	dialog[open] {
		@apply translate-x-0 transition-all ease-in-out duration-500;
	}
</style>
