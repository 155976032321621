<script lang="ts">
	import { storyblokEditable } from '@storyblok/svelte';
	import type { ImageStoryblok, LinkStoryblok } from '$lib/storyblok/types';
	import { page } from '$app/stores';
	import Image from '$lib/components/Image/Image.svelte';
	import { resolveRoute } from '$app/paths';
	import { createBreakpointStore } from '$lib/store/breakpoint.store';
	import { SUPPORTED_LANGUAGES } from '$lib/i18n/constants';
	import { getImagePriorityBelowFold } from '$lib/utils/image';
	import StoryblokImageOrVideo from '$lib/components/Image/StoryblokImageOrVideo.svelte';
	import { categoryHandleToUrl } from '$lib/utils/collectionTree';

	interface EditorialSectionTenProps {
		headline: string;
		title_1: string;
		title_2: string;
		title_3?: string;
		link_1?: LinkStoryblok;
		link_2?: LinkStoryblok;
		link_3?: LinkStoryblok;
		image_1?: ImageStoryblok;
		image_2?: ImageStoryblok;
		image_3?: ImageStoryblok;
	}

	export let blok: EditorialSectionTenProps;
	export let position: number = 0;
	$: isBelowFold = position > 0;

	$: titles = [blok.title_1, blok.title_2, blok.title_3].filter((s) => !!s);
	$: links = [blok.link_1, blok.link_2, blok.link_3]
		.filter((s) => !!s)
		.map((s) => {
			const urlParts = (s?.cached_url.split('/') || []).filter(Boolean);
			if (urlParts.length > 0 && SUPPORTED_LANGUAGES.includes(urlParts[0])) {
				urlParts.shift();
			}
			return {
				...s,
				cached_url: urlParts.join('/')
			};
		});
	$: images = [blok.image_1, blok.image_2, blok.image_3].filter((s) => !!s);

	$: displayedItemLength = Math.min(titles.length, links.length, images.length);
	const headline = blok.headline;
	const mobileStore = createBreakpointStore();
	$: isMobile = $mobileStore;
</script>

<div use:storyblokEditable={blok} class="flex flex-col py-11 md:py-16">
	<h2 class="ml-3 md:ml-11 mb-4">{headline}</h2>
	<div
		class="grid"
		style={`grid-template-columns: repeat(${isMobile ? 1 : displayedItemLength}, minmax(0, 1fr));`}
	>
		{#each images as image, index}
			{#if image?.filename && links[index] && titles[index]}
				{@const collectionHandle = links[index]?.cached_url.split('/')[1]}
				<a
					href={categoryHandleToUrl(
						$page.params.locale,
						$page.params.country,
						$page.data.collectionTree,
						collectionHandle
					)}
				>
					<div class="relative aspect-[1.2] overflow-hidden md:w-full">
						{#if image.filename}
							<div
								class="flex-1 relative transition-all duration-1000 hover:cursor-pointer hover:brightness-[0.75] w-full h-full"
							>
								<Image
									class="relative scale-100 transition-all duration-1000 ease-in-out transform hover:scale-[1.125] object-cover h-full w-full"
									src={image.filename}
									placeholder="blur"
									focus={image.focus}
									alt={image.alt}
									layout="fill"
									sizes={isMobile ? '100vw' : '34vw'}
									title={image.title}
									{...getImagePriorityBelowFold(isBelowFold)}
								/>
								<!-- //blurDataURL={withBlur(image.filename)} -->
							</div>
						{/if}
						{#if titles[index]}
							<h2 class="pointer-events-none text-white left-6 bottom-6 absolute z-10">
								{titles[index]}
							</h2>
						{/if}
					</div>
				</a>
			{/if}
		{/each}
	</div>
</div>
