<script lang="ts">
	import { page } from '$app/stores';
	import { collectionSideDrawer } from '$lib/store/collectionSideDrawer.store';
	import { customerSessionDrawer } from '$lib/store/customerSessionDrawer.store';
	import { scrollNavBarHandler } from '$lib/store/scrollNavBarHandler.store';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { getTranslate } from '@tolgee/svelte';
	import { cart } from '$lib/store/cart.store';
	import { layoutNavbarLinks } from '$lib/nav';
	import SearchBar from '$lib/components/NavBar/SearchBar.svelte';
	import { desktopNavBarExpansionStore } from '$lib/store/desktopNavBarExpansion.store';
	import LanguagePicker from '$lib/components/Language/LanguagePicker.svelte';
	import DiscountBar from '$lib/components/NavBar/DiscountBar.svelte';
	import InfoBar from '$lib/components/NavBar/InfoBar.svelte';
	import { onMount } from 'svelte';
	import { navBarSize } from '$lib/store/navBarSize.store';
	import NavigationSpacer from '$lib/components/NavBar/NavigationSpacer.svelte';
	import { customerSessionStore } from '$lib/store/customerSession.store';
	import { resolveRoute } from '$app/paths';
	import GenericPicker from '$lib/components/GenericPicker.svelte';
	import { customer } from '$lib/store/customer.store';
	import {
		NOTIFICATION,
		NOTIFICATION_DURATION,
		notificationStore
	} from '$lib/store/notification.store';
	import { goto } from '$app/navigation';
	import StagingBanner from '$lib/components/StagingBanner.svelte';
	import { createBreakpointStore } from '$lib/store/breakpoint.store';
	import { browser } from '$app/environment';
	import { isNetworkError } from '$lib/utils';
	import { categoryHandleToUrl } from '$lib/utils/collectionTree';
	import { b2bSessionDrawer } from '$lib/store/b2bSessionDrawer.store';

	const { t } = getTranslate();
	const isMobile = createBreakpointStore();

	let scrollY = 0;

	$: scrollNavBarHandler.setScrollY(scrollY);
	let navRef: HTMLElement;
	let customerPickerExpanded = false;

	let SlideOutMenu: typeof import('./SlideOutMenu.svelte').default;

	onMount(() => {
		const observer = new ResizeObserver(() => {
			requestAnimationFrame(() => {
				navBarSize.updateSize(navRef.getBoundingClientRect().height);
			});
		});
		navBarSize.updateSize(navRef.getBoundingClientRect().height);
		observer.observe(navRef);

		return () => {
			observer.disconnect();
		};
	});

	let retries = 0;

	$: if (browser && !$isMobile && retries < 3) {
		import('./SlideOutMenu.svelte')
			.then((m) => {
				SlideOutMenu = m.default;
			})
			.catch((e) => {
				if (isNetworkError(e)) {
					setTimeout(() => retries++, 2000 * (retries + 1));
					return;
				}
				throw e;
			});
	}
</script>

<svelte:window bind:scrollY />
<nav
	bind:this={navRef}
	class="fixed left-0 top-0 z-50 w-full transform transition duration-500"
	class:-translate-y-full={!$scrollNavBarHandler.scrollNavBarVisible}
	class:bg-white={!$scrollNavBarHandler.isColorfulBar}
	class:bg-accent-primary={$scrollNavBarHandler.isColorfulBar}
	class:text-white={$scrollNavBarHandler.isColorfulBar}
	on:mouseleave={() => {
		desktopNavBarExpansionStore.setIsExpanded(false);
	}}
>
	<div>
		<StagingBanner />
		<DiscountBar />
		<InfoBar />
		<div
			class:md:bg-white={!$scrollNavBarHandler.isColorfulBar}
			class:md:bg-accent-primary={$scrollNavBarHandler.isColorfulBar}
			class:md:text-white={$scrollNavBarHandler.isColorfulBar}
			class="h-16 flex justify-between items-center px-3 sm:pr-4 lg:px-8 z-20"
		>
			<div class="flex items-center gap-4">
				<a href="/" class="i-sprenger-logo-black-horizontal w-40 h-16">
					<span class="sr-only">{$t('breadcrumbs.home')}</span>
				</a>
			</div>

			<div class="flex items-center gap-6">
				<div class="relative flex items-center gap-6">
					{#each layoutNavbarLinks as link}
						{@const isActive = $page.route.id === link.url}
						<a
							on:mouseenter={() => {
								if (link.navPart !== 'magazin') {
									desktopNavBarExpansionStore.setIsExpanded(true);
									desktopNavBarExpansionStore.setLastBranch(
										typeof $page.data.collectionTree.link !== 'undefined'
											? link.navPart
											: link.newNavPart
									);
								} else {
									desktopNavBarExpansionStore.setIsExpanded(false);
								}

								// TODO
							}}
							on:click={() => {
								desktopNavBarExpansionStore.setIsExpanded(false);
								desktopNavBarExpansionStore.preventImmediateChanges();
							}}
							class={classes(
								'hidden uppercase hover:pt-[2px] hover:border-b-2 font-thin hover:font-extrabold hover:no-underline hover:border-current !flex-0',
								!$scrollNavBarHandler.isColorfulBar
									? !isActive && 'text-inherit hover:text-accent-primary'
									: 'text-white border-white',
								isActive &&
									`pt-[2px] border-b-2 font-extrabold border-current ${
										!$scrollNavBarHandler.isColorfulBar
											? 'text-accent-primary'
											: 'text-white border-white'
									}`,
								link.url === '/[locale]-[country]/faq' || link.url === '/[locale]-[country]/magazin'
									? 'xl:block'
									: 'lg:block'
							)}
							href={link.url
								? resolveRoute(link.url, { ...$page.params })
								: categoryHandleToUrl(
										$page.params.locale,
										$page.params.country,
										$page.data.collectionTree,
										link.newNavPart
									)}
						>
							{$t(link.name)}
						</a>
					{/each}
					<div class="hidden md:block">
						<SearchBar />
					</div>
					<div class="hidden md:block">
						<LanguagePicker />
					</div>
					{#if $customerSessionStore.isLoggedIn}
						<GenericPicker
							bind:isExpanded={customerPickerExpanded}
							class="flex flex-col justify-start min-w-42 gap-8 !p-5 z-40 !text-black"
						>
							<svelte:fragment slot="opened-content">
								<span class="text-left whitespace-nowrap">
									{$t('hello')}, <b class="ph-no-capture">{$customer?.displayName ?? ''}</b>
								</span>
								<a
									href={resolveRoute('/[locale]-[country]/customer', {
										...$page.params
									})}>{$t('customer.customer')}</a
								>
								<a
									href={resolveRoute('/[locale]-[country]/customer/orders', {
										...$page.params
									})}>{$t('orders.headline')}</a
								>
								<a
									href={resolveRoute('/[locale]-[country]/customer/address-book', {
										...$page.params
									})}>{$t('customer.address-book')}</a
								>
								<a
									href={resolveRoute('/[locale]-[country]/customer/profile', {
										...$page.params
									})}>{$t('customer.profile.title')}</a
								>
								<button
									class="w-fit"
									on:click={async (e) => {
										e.stopPropagation();
										await customerSessionStore.logout();
										notificationStore.addNotification({
											hideAfter: NOTIFICATION_DURATION.MEDIUM,
											text: $t('modals.logout.success.content'),
											title: $t('modals.logout.success.title'),
											variant: NOTIFICATION.SUCCESS
										});
										if ($page.route.id?.includes('customer')) {
											await goto(
												resolveRoute('/[locale]-[country]/', {
													...$page.params
												})
											);
										}
										customerPickerExpanded = false;
									}}
								>
									{$t('logout')}
								</button>
							</svelte:fragment>
							<div class="flex">
								<div class="i-sprenger-user-logged-in w-5 h-5 block flex-none"></div>
								<div
									class="i-sprenger-chevron-down w-5 h-5 transition transform"
									class:rotate-180={customerPickerExpanded}
								></div>
							</div>
						</GenericPicker>
					{:else}
						<!-- Für Handler Button -->
						<button
							on:click={() => b2bSessionDrawer.toggle()}
							class="flex flex-col items-center"
							aria-label={$t('customerSession.drawer.b2b.button')}
						>
							<span class="i-sprenger-briefcase w-5 h-5 block flex-none" />
							<span class="hidden lg:flex">{$t('customerSession.drawer.b2b.button')}</span>
						</button>
						<button
							on:click={() => customerSessionDrawer.toggle()}
							class="flex flex-col items-center"
							aria-label={$t('customerSession.outer.loginOrRegister')}
						>
							<span class="i-sprenger-user w-5 h-5 block flex-none" />
							<span class="hidden lg:flex">{$t('customerSession.outer.login')}</span>
						</button>
					{/if}
					<a
						href={resolveRoute('/[locale]-[country]/cart', {
							...$page.params
						})}
						class="flex flex-col items-center"
					>
						<div class="h-5 w-5 relative">
							<span class="i-sprenger-shopping-bag w-5 h-5 block" />
							{#if $cart.totalQuantity > 0}
								<div
									class="absolute -top-2 -right-2 w-4 h-4 bg-accent-primary rounded-full flex justify-center items-center text-xs text-white"
								>
									{$cart.totalQuantity}
								</div>
							{/if}
						</div>
						<span class="hidden lg:flex">{$t('shoppingCart')}</span>
					</a>
					<button
						on:click={() => collectionSideDrawer.toggle()}
						class={classes(
							'w-5 h-5 md:hidden',
							$collectionSideDrawer.isExpanded ? 'i-sprenger-x' : 'i-sprenger-menu'
						)}
					>
						<span class="sr-only">
							{$t('menu')}
						</span>
					</button>
				</div>
			</div>
		</div>
		<div class="md:hidden block px-3 pb-3">
			<SearchBar />
		</div>
	</div>
</nav>
<svelte:component this={SlideOutMenu} />
<NavigationSpacer />
