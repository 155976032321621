<script lang="ts">
	import { onMount } from 'svelte';
	import { getTranslate } from '@tolgee/svelte';
	import type { NewsletterBlockProps } from '$lib/storyblok/types';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import ButtonPrimary from '$lib/components/Button/ButtonPrimary.svelte';
	import StoryblokImageOrVideo from '$lib/components/Image/StoryblokImageOrVideo.svelte';
	import FormFieldCheckbox from '$lib/components/Form/FormFieldCheckbox.svelte';
	import { field, form } from 'svelte-forms';
	import FormFieldTextInput from '$lib/components/Form/FormFieldTextInput.svelte';
	import RichText from '$lib/components/Storyblok/RichText.svelte';
	import {
		NOTIFICATION,
		NOTIFICATION_DURATION,
		notificationStore
	} from '$lib/store/notification.store';
	import { createSubscription } from '$lib/klaviyo';

	export let blok: NewsletterBlockProps;

	const { t } = getTranslate();
	const showVerifyEmail = () =>
		notificationStore.addNotification({
			hideAfter: NOTIFICATION_DURATION.LONG,
			text: $t('modals.newsletterSettings.confirm.content'),
			title: $t('modals.newsletterSettings.confirm.title'),
			variant: NOTIFICATION.ALERT
		});

	$: hasImage = blok.image && blok.image.filename;

	const [equineCheckbox, dogCheckbox, boatCheckbox, email] = [
		field('listEquine', true),
		field('listDog', true),
		field('listBoat', true),
		field('email', '')
	];
	const newsletterForm = form(equineCheckbox, dogCheckbox, boatCheckbox, email);
	$: errors = $newsletterForm.errors;

	const handleSubmit = async () => {
		const isEverythingSelected =
			!$dogCheckbox.value && !$boatCheckbox.value && !$equineCheckbox.value;
		await createSubscription($email.value, 'newsletter', {
			listDog: isEverythingSelected || $dogCheckbox.value,
			listBoat: isEverythingSelected || $boatCheckbox.value,
			listEquine: isEverythingSelected || $equineCheckbox.value
		});
		showVerifyEmail();
	};
</script>

<div
	class={classes(
		'flex bg-accent-off-white gap-6 md:min-h-[35.5rem] max-w-[1920px] mx-auto',
		hasImage
			? blok.image_emphasis === 'right'
				? 'flex-col-reverse md:flex-row md:items-stretch'
				: 'flex-col md:flex-row-reverse'
			: 'flex-col justify-evenly'
	)}
>
	<div
		class="p-3 flex gap-6 items-center mt-10 md:mt-14 md:px-10 {hasImage
			? 'md:w-1/3 flex-col'
			: 'justify-center flex-col md:flex-row'}"
	>
		<div class={!hasImage ? 'max-w-md' : 'space-y-6'}>
			<h1 class={hasImage ? 'text-center' : 'md:text-left text-center'}>{blok.title}</h1>
			<h5 class={hasImage ? 'text-center' : 'md:text-left text-center'}>
				<RichText text={blok.description} />
			</h5>
		</div>
		<form
			class="flex flex-col gap-4 flex-wrap w-full {hasImage ? '' : 'max-w-md'}"
			on:submit|preventDefault={handleSubmit}
		>
			<div class="space-y-2">
				<div class="text-accent-primary text-p-12">{$t('newsletter.list.title')}</div>
				<div class="flex gap-8">
					<FormFieldCheckbox bind:checked={$equineCheckbox.value} {errors} name="listEquine">
						{$t('newsletter.list.equine')}
					</FormFieldCheckbox>
					<FormFieldCheckbox bind:checked={$dogCheckbox.value} {errors} name="listDog">
						{$t('newsletter.list.dog')}
					</FormFieldCheckbox>
					<FormFieldCheckbox bind:checked={$boatCheckbox.value} {errors} name="listBoat">
						{$t('newsletter.list.boat')}
					</FormFieldCheckbox>
				</div>
			</div>
			<FormFieldTextInput
				type="email"
				label={$t('customer.email')}
				bind:value={$email.value}
				{errors}
				name="email"
			/>
			<ButtonPrimary
				text={blok.button_cta}
				class="md:w-auto w-full"
				type="submit"
				iconPosition="right"
			>
				<div slot="icon" class="w-5 h-5 i-sprenger-arrow-down transform rotate-[270deg]"></div>
			</ButtonPrimary>
		</form>
		{#if hasImage}
			<div class="w-48 h-32 i-sprenger-signature-hermann-sprenger-germany-black" />
		{/if}
	</div>
	{#if !hasImage}
		<div class="w-48 h-32 i-sprenger-signature-hermann-sprenger-germany-black mb-10 mx-auto" />
	{/if}
	{#if hasImage}
		<div class="relative md:w-2/3 w-full aspect-[23.43/18.18] md:aspect-auto">
			<StoryblokImageOrVideo
				{...blok.image}
				objectFit="cover"
				class="object-cover w-full h-full"
				layout="fill"
			/>
		</div>
	{/if}
</div>
