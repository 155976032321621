export const layoutNavbarLinks = [
	{
		name: 'nav.pferdesport',
		navPart: 'pferdesport-root',
		newNavPart: '300d1734',
		url: '/[locale]-[country]/l/reitsport'
	},
	{
		name: 'nav.hundesport',
		navPart: 'hundesport-root',
		newNavPart: '825406ab',
		url: '/[locale]-[country]/l/hundesport'
	},
	{
		name: 'nav.bootsport',
		navPart: 'bootsport-root',
		newNavPart: '1bec92b0',
		url: '/[locale]-[country]/l/bootsport-vc-17m-verbot'
	},
	{
		name: 'nav.sprenger-and-me',
		navPart: 'sprenger-me-root',
		newNavPart: '2035d535',
		url: ''
	},
	{ name: 'nav.faq', navPart: 'faq', newNavPart: 'faq', url: '/[locale]-[country]/faq' },
	{ name: 'nav.magazin', navPart: 'magazin', url: '/[locale]-[country]/magazin' },
	{
		name: 'nav.aboutUs.title',
		navPart: 'about',
		newNavPart: 'about',
		url: '/[locale]-[country]/about-us'
	}
];
