import { derived, writable } from 'svelte/store';
import type { NavBarBranch, RootCollection } from '$lib/types/Navigation/branch';
import { BRANCH_COLLECTION_LOOKUP } from '$lib/utils/product';

const createDesktopNavBarExpansionStore = () => {
	const isExpanded = writable(false);
	let preventsModification = false;
	const lastHoveredBranch = writable<NavBarBranch | undefined>(undefined);

	const data = derived([isExpanded, lastHoveredBranch], ([$isExpanded, $lastHoveredBranch]) => {
		return {
			isExpanded: $isExpanded,
			lastHoveredBranch: $lastHoveredBranch,
			lastHoveredCollection: $lastHoveredBranch
		};
	});

	return {
		...data,
		setLastBranch: (branch: NavBarBranch | undefined) => lastHoveredBranch.set(branch),
		preventImmediateChanges: () => {
			preventsModification = true;
			setTimeout(() => (preventsModification = false), 300);
		},
		setIsExpanded: (value: boolean) => {
			isExpanded.set(value);
		}
	};
};

export const desktopNavBarExpansionStore = createDesktopNavBarExpansionStore();
