<script lang="ts">
	import type { HeroSliderBlockProps } from '$lib/storyblok/types';
	import { primaryColorDict, secondaryColorDict } from '$lib/color';
	import Image from '$lib/components/Image/Image.svelte';
	import { navBarSize } from '$lib/store/navBarSize.store';
	import { onMount } from 'svelte';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import ButtonPrimary from '$lib/components/Button/ButtonPrimary.svelte';
	import { storyblokEditable } from '@storyblok/svelte';
	import { createBreakpointStore } from '$lib/store/breakpoint.store';
	import { getImagePriorityBelowFold } from '$lib/utils/image';

	export let blok: HeroSliderBlockProps;
	let currentIndex = 0;
	let preventNext = false;
	let currentIndexDelayedBefore = currentIndex;
	let innerHeight = 1080;

	$: if (currentIndexDelayedBefore !== currentIndex) {
		setTimeout(() => {
			currentIndexDelayedBefore = currentIndex;
		}, 1500);
	}

	$: selectedSlide = blok.slides[currentIndex];
	$: slideColors = {
		primary: primaryColorDict[selectedSlide.content_branch] || primaryColorDict.dog,
		secondary: secondaryColorDict[selectedSlide.content_branch] || secondaryColorDict.dog
	};
	const mobileStore = createBreakpointStore();
	$: isMobile = $mobileStore;
	let titleSize = '3rem';

	const getFontSize = (text: string) => {
		const viewWidth = typeof window === 'undefined' ? 1440 : window.innerWidth;
		const sectionWidth = viewWidth >= 640 ? viewWidth / 2 - viewWidth * 0.05 : viewWidth - 32;
		const percentageOfViewport = sectionWidth / viewWidth;

		const maxFontSize = 9.5;
		const minFontSize = 1.5;

		const longestWord = Math.max(...text.split(' ').map((l) => l.length));
		const textLimit = 30;

		const textSizeFactor = 1 - Math.max(0.16, longestWord / textLimit) + 0.16;

		const fontSize = `${textSizeFactor * percentageOfViewport * 26}vw`;
		return `clamp(${minFontSize}rem, ${maxFontSize}rem, ${fontSize})`;
	};
	$: titleSize = getFontSize(selectedSlide.title);
	let touchXStart = 0;

	onMount(() => {
		setInterval(() => {
			if (preventNext) {
				preventNext = false;
				return;
			}
			currentIndex = (currentIndex + 1) % blok.slides.length;
		}, 5000);
	});
</script>

<svelte:window bind:innerHeight />

<div
	use:storyblokEditable={blok}
	class="hero-slider grid relative"
	style:height={isMobile ? '' : `calc(100vh - ${$navBarSize}px)`}
>
	<div class="image-gap relative hidden md:block">
		{#each blok.slides as { bg_image }, i}
			<Image
				{...getImagePriorityBelowFold(false)}
				src={bg_image.filename}
				width={20}
				sizes="1vw"
				class={classes(
					'h-full w-full object-cover object-left absolute opacity-0 transition duration-1500',
					i === currentIndex ? 'opacity-100' : ''
				)}
			/>
		{/each}
	</div>
	<div
		class="text relative min-h-lg bg-white overflow-x-hidden overflow-y-auto scrollbar-hide mt-8"
	>
		{#each blok.slides as slide, i}
			<div
				class:opacity-100={i === currentIndex}
				class:z-30={currentIndexDelayedBefore === i || currentIndex === i}
				class="absolute w-full flex flex-col justify-between items-center h-full left-1/2 transform -translate-x-1/2 transition duration-1500 opacity-0"
			>
				{#if innerHeight > 720}
					<div></div>
					<div></div>
				{/if}
				<a
					class="md:hidden inline z-30 relative"
					href={slide.href.url || `${slide.href.cached_url}`}
				>
					<ButtonPrimary text={slide.call_to_action} />
				</a>
				<div class="grid place-items-center">
					<h1
						style:font-size={titleSize}
						style:line-height="100%"
						class="text-h-72 my-4 md:my-16 md:text-h-126 lg-after:text-h-152 break-words max-w-xs md:max-w-md mx-auto text-center transition duration-1500 transform"
						class:translate-x-full={i - 1 === currentIndex ||
							(i === 0 && currentIndex === blok.slides.length - 1)}
						class:-translate-x-full={i + 1 === currentIndex ||
							(i === blok.slides.length - 1 && currentIndex === 0)}
					>
						{slide.title}
					</h1>
					<div class="text-accent-primary text-h-16 text-center max-w-sm px-8 hidden md:block">
						{slide.subtitle}
					</div>
					<a class="hidden md:block mt-10" href={slide.href.url || `${slide.href.cached_url}`}>
						<ButtonPrimary text={slide.call_to_action} />
					</a>
				</div>
				<div class="i-sprenger-signature-hermann-sprenger-germany-black flex-none w-42 h-32"></div>
				<div class="text-accent-primary text-h-16 text-center px-4 mt-6 md:hidden block">
					{slide.subtitle}
				</div>
			</div>
		{/each}
	</div>
	<div class="big-image relative">
		{#each blok.slides as { bg_image }, i}
			<Image
				{...getImagePriorityBelowFold(false)}
				src={bg_image.filename}
				alt={bg_image.alt}
				title={bg_image.title}
				class={classes(
					'h-full w-full object-cover absolute opacity-0 transition duration-1500',
					i === currentIndex ? 'opacity-100' : ''
				)}
				sizes="60vw"
			/>
		{/each}
	</div>
	<div
		class="secondary-gap transition duration-1500"
		style:background-color={slideColors.secondary}
	></div>
	<div
		class="secondary-gap-long hidden transition duration-1500 md:flex justify-center items-end pb-8"
		style:background-color={slideColors.secondary}
	>
		<div class="flex items-center gap-2 relative md:z-40">
			{#each blok.slides as _, i}
				<button
					on:click={() => {
						preventNext = true;
						currentIndex = i;
					}}
					class="bg-accent-primary border-2 rounded-full w-2 h-2 transition duration-1000"
					class:w-3={i === currentIndex}
					class:h-3={i === currentIndex}
					class:bg-transparent={i === currentIndex}
					class:border-accent-primary={i === currentIndex}
					class:border-transparent={i !== currentIndex}
				></button>
			{/each}
		</div>
	</div>
	<div
		class="primary-gap md:z-30 transition duration-1500"
		style:background-color={slideColors.primary}
	></div>

	<div
		on:touchstart={(e) => {
			touchXStart = e.touches[0].clientX;
		}}
		on:touchend={(e) => {
			const touchXEnd = e.changedTouches[0].clientX;
			if (touchXStart - touchXEnd > 50) {
				preventNext = true;
				currentIndex = (currentIndex + 1) % blok.slides.length;
			} else if (touchXStart - touchXEnd < -50) {
				preventNext = true;
				currentIndex = (currentIndex - 1 + blok.slides.length) % blok.slides.length;
			}
		}}
		class="absolute w-full h-full left-0 top-0"
	>
		<div class="relative w-full h-full overflow-hidden">
			{#each blok.slides as { foreground_image }, i}
				<div
					class="absolute z-20 right-0 md:-bottom-8 md:-top-20 md:top-auto transition transform duration-1500 h-[60%] w-1/2 md:w-[60%]"
					class:translate-x-full={currentIndex !== i}
					class:delay-1500={i === currentIndex}
				>
					<Image
						{...getImagePriorityBelowFold(false)}
						src={foreground_image.filename}
						class="w-full h-full object-contain"
						sizes="40vw"
					/>
				</div>
			{/each}
		</div>
	</div>
</div>

<style>
	.hero-slider {
		grid-template-columns: 1fr;
		grid-template-rows: 18rem 1rem 1fr;
		grid-template-areas: 'big-image' 'primary-gap' 'text';
	}

	@media screen and (min-width: 768px) {
		.hero-slider {
			grid-template-columns: 1.5% 48.5% 50%;
			grid-template-rows: 1fr 11rem 1.5rem;
			grid-template-areas:
				'image-gap text big-image'
				'secondary-gap text secondary-gap-long'
				'primary-gap primary-gap primary-gap';
		}
	}

	.image-gap {
		grid-area: image-gap;
	}

	.text {
		grid-area: text;
	}

	.big-image {
		grid-area: big-image;
	}

	.secondary-gap {
		grid-area: secondary-gap;
	}
	.secondary-gap-long {
		grid-area: secondary-gap-long;
	}

	.primary-gap {
		grid-area: primary-gap;
	}
</style>
