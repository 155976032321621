<script>
	import { classes } from '@thearc-hq/ui-kit/utils';

	let scrollY = 0;
	let totalHeight = 1;

	$: shouldShow = scrollY / totalHeight > 1;
</script>

<svelte:window bind:scrollY bind:outerHeight={totalHeight} />

<button
	on:click={() =>
		window.scrollTo({
			behavior: 'smooth',
			top: 0
		})}
	class={classes(
		'bg-accent-primary rounded-full back-to-top h-12 w-12 fixed md:bottom-24 bottom-[1.2rem] md:right-10 right-[5.5rem] p-1 z-30 transform transition duration-500',
		shouldShow ? 'scale-100' : 'scale-0'
	)}
>
	<span class="block text-white i-sprenger-chevron-up w-10 h-10" />
</button>
