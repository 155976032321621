<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { page } from '$app/stores';
	import { getTranslate } from '@tolgee/svelte';
	import CollectionTreeItem from '$lib/components/Drawer/CollectionTreeItem.svelte';
	import NavigationSpacer from '$lib/components/NavBar/NavigationSpacer.svelte';
	import { collectionSideDrawer } from '../../store/collectionSideDrawer.store';
	import { resolveRoute } from '$app/paths';
	import { createEventDispatcher } from 'svelte';
	import { createBreakpointStore } from '$lib/store/breakpoint.store';
	import type { CollectionTree } from '$lib/utils/collectionTree';
	import { COLLECTION_TO_LANDING_PAGE_LOOKUP } from '$lib/utils/product';

	export let currentTree: CollectionTree[] = [];
	export let initialTreeClasses = '';
	export let followingTreeClasses = '';
	export let additionalDepth = 0;
	$: depth = Math.max(currentTree.length - 1, 0);
	const isMobile = createBreakpointStore();

	const dispatch = createEventDispatcher();

	const { t } = getTranslate();
</script>

{#if $collectionSideDrawer.isExpanded}
	<div
		role="button"
		tabindex="0"
		on:keypress={() => collectionSideDrawer.setExpanded(false)}
		on:click={() => collectionSideDrawer.setExpanded(false)}
		class="absolute w-full h-full left-0 top-0"
	></div>
{/if}
{#each currentTree as treeData, i}
	{@const skippedDepth = i + additionalDepth}
	<div
		class={classes(
			'flex flex-col items-stretch h-full px-3 py-8 pb-56 w-full overflow-y-auto md:px-4 lg:px-16 pt-10 gap-6 absolute md:relative',
			skippedDepth === 0
				? 'bg-accent-primary'
				: skippedDepth > 1
					? 'bg-accent-primary_01'
					: 'bg-accent-primary_02',
			skippedDepth > 0
				? `text-accent-primary ${followingTreeClasses}`
				: `text-white ${initialTreeClasses}` // md:max-w-sm md:max-w-md
		)}
		style:z-index={(skippedDepth + 1) * 2}
	>
		<NavigationSpacer class="flex-none" />
		{#if depth + additionalDepth > 0 && skippedDepth > 0}
			<div class="flex justify-between">
				{#if depth > 0 && i > additionalDepth}
					<button on:click={() => (currentTree = currentTree.slice(0, i))}>
						<div class="i-sprenger-chevron-left w-6 h-6"></div>
					</button>
				{/if}
				<a
					on:click={() => dispatch('close')}
					href={COLLECTION_TO_LANDING_PAGE_LOOKUP[currentTree[i].handle || currentTree[i].link] &&
					$isMobile
						? resolveRoute(
								COLLECTION_TO_LANDING_PAGE_LOOKUP[currentTree[i].handle || currentTree[i].link],
								{
									...$page.params
								}
							)
						: currentTree[i].link?.includes('/')
							? resolveRoute(currentTree[i].link, {
									...$page.params
								})
							: resolveRoute('/[locale]-[country]/[...handle]', {
									...$page.params,
									handle: [
										...currentTree
											.slice(0, i + 1)
											.filter((s) => !!s.url_key)
											.map((s) => s.url_key),
										...(currentTree[i].handle ? [currentTree[i].handle] : [])
									].join('/')
								})}
					class="text-h-24"
				>
					{currentTree[i].title.includes('nav.') ? $t(currentTree[i].title) : currentTree[i].title}
				</a>
				<div class="w-6 h-6" />
			</div>
		{/if}
		{#each treeData?.items || [] as item}
			{@const hasSubItems = (item.items?.length ?? 0) > 0}
			{#if hasSubItems}
				<button
					class="text-left"
					on:click={() => (currentTree = [...currentTree.slice(0, i + 1), item])}
				>
					<CollectionTreeItem {item} index={skippedDepth} depth={depth + additionalDepth} />
				</button>
			{:else}
				<a
					on:click={() => dispatch('close')}
					href={item.link?.includes('/')
						? resolveRoute(item.link, {
								...$page.params
							})
						: resolveRoute('/[locale]-[country]/[...handle]', {
								...$page.params,
								handle: [
									...[...currentTree, item].filter((s) => !!s.url_key).map((s) => s.url_key),
									...(item.handle ? [item.handle] : [])
								].join('/')
							})}
				>
					<CollectionTreeItem {item} index={skippedDepth} depth={depth + additionalDepth} />
				</a>
			{/if}
		{/each}

		{#if i === 0 && $$slots.bottom}
			<slot name="bottom" />
		{/if}
	</div>
{/each}
