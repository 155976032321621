<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { T } from '@tolgee/svelte';

	let className = '';

	export { className as class };
</script>

<div class={classes('flex flex-col gap-2', className)}>
	<span class="text-h-16">
		<T keyName="footer_paymentmethods_headline" />
	</span>
	<div class="flex gap-1">
		<div class="w-11 i-sprenger-mastercard" />
		<div class="w-11 i-sprenger-visa" />
		<div class="w-11 i-sprenger-maestro" />
		<div class="w-11 i-sprenger-paypal" />
		<div class="w-11 i-sprenger-klarna" />
		<div class="w-11 i-sprenger-sofort" />
	</div>
</div>
