<script lang="ts">
	import type { ContentBlock11Props } from '$lib/storyblok/types';
	import { storyblokEditable } from '@storyblok/svelte';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import ImageHover from '$lib/components/Image/ImageHover.svelte';
	import StoryblokImageOrVideo from '$lib/components/Image/StoryblokImageOrVideo.svelte';
	import RichText from '$lib/components/Storyblok/RichText.svelte';
	import { primaryColorDict } from '$lib/color';
	import BorderElement from './BorderElement.svelte';
	import { createBreakpointStore } from '$lib/store/breakpoint.store';
	import { getImagePriorityBelowFold } from '$lib/utils/image';

	export let blok: ContentBlock11Props;

	export let position: number = 0;
	$: isBelowFold = position > 0;
	const mobileStore = createBreakpointStore();
	$: isMobile = $mobileStore;

	$: backgroundColor = primaryColorDict[blok.content_branch] || primaryColorDict.sprenger;
	$: contentBranchSelected = blok.content_branch !== 'noBranding';
</script>

<div
	use:storyblokEditable={blok}
	class={classes('relative my-11 md:my-16', blok.blue_background && 'bg-accent-primary_005')}
>
	<div
		class={classes(
			'relative flex flex-col-reverse gap-4 lg:gap-5 !mt-0 overflow-hidden h-full',
			blok.image_left ? 'lg:flex-row-reverse' : 'lg:flex-row'
		)}
	>
		<!-- 
			- 100vw: Full viewport width
			- 104rem: Max-width of the container
			- 15px: Approximate scrollbar width
			- (100vw - 104rem - 15px) / 2: The remaining space on either side of the centered container, divided by 2 (aka. the mx-auto)
		 -->
		<div
			class="flex lg:self-end lg:pb-16 grow-0 pb-4 before:content-[''] before:shrink-0 before:w-3 md:pl-5% lg:before:w-[calc((100vw-104rem-15px)/2)]"
		>
			<div class="flex flex-col lg:max-w-md lg:w-full">
				{#if blok.title}
					<div class="text-h-32 pb-4 hyphens-auto">{blok.title}</div>
				{/if}
				<RichText class="text-p-16" text={blok.description} />
			</div>
		</div>
		<div class="flex-1 relative">
			{#if blok.image_1?.filename && blok.image_2?.filename && !blok.video_id}
				<ImageHover
					class="right-0 aspect-3/2 lg:aspect-video h-full w-full"
					image_1={blok.image_1}
					image_2={blok.image_2}
					objectFit="cover"
					imageProps={getImagePriorityBelowFold(isBelowFold)}
				/>
			{:else}
				<StoryblokImageOrVideo
					filename={blok.image_1?.filename}
					alt={blok.image_1?.alt ?? undefined}
					focus={blok.image_1?.focus}
					width={2560}
					height={1440}
					sizes="75vw"
					video_id={blok.video_id}
					autoplay={blok.autoplay}
					shopTheLook={blok.shopTheLook}
					class="object-cover lg:max-h-148 2xl:max-h-132 lg:h-full"
					title={blok.image_1?.title}
					{...getImagePriorityBelowFold(isBelowFold)}
				/>
			{/if}
			{#if contentBranchSelected && isMobile}
				<BorderElement color={backgroundColor} isLeft={!blok.image_left} />
			{/if}
		</div>
	</div>
	{#if contentBranchSelected && !isMobile}
		<BorderElement color={backgroundColor} isLeft={!blok.image_left} />
	{/if}
</div>
