<script lang="ts">
	import GenericPicker from '$lib/components/GenericPicker.svelte';
	import { languageStore } from '$lib/store/language.store';
	import { shippingMarkets } from '$lib/utils/shippingCountries';
	import { getTranslate, T } from '@tolgee/svelte';
	import { SUPPORTED_COUNTRIES, SUPPORTED_LANGUAGES } from '$lib/i18n/constants';
	import { languageCodeToCountryCode } from '$lib/utils/i18n';

	const { t } = getTranslate();
</script>

<GenericPicker class="w-64 text-black">
	<svelte:fragment slot="opened-content">
		<div class="flex flex-col gap-4">
			<T keyName="languagePicker.title" />
			<div class="flex flex-col">
				{#each SUPPORTED_LANGUAGES as locale}
					<button
						class="flex items-center gap-2 w-full py-2 px-2 rounded-md hover:bg-gray-100"
						on:click={() => languageStore.changeLocale(locale)}
					>
						<div
							class={`i-flag-${languageCodeToCountryCode(locale)} w-7 h-5 rounded-sm shrink-0`}
						></div>
						<div>{$t(`languagePicker.languages.${locale.toLowerCase()}`)}</div>
					</button>
				{/each}
			</div>
			<T keyName="shipping_selection_headline" />
			<div class="flex flex-col">
				{#each SUPPORTED_COUNTRIES as country}
					<button
						class="flex items-center gap-2 w-full py-2 px-2 rounded-md hover:bg-gray-100"
						on:click={() => {
							languageStore.changeCountry(country);
						}}
					>
						<div class={`i-flag-${country.toLowerCase()} w-7 h-5 rounded-sm shrink-0`}></div>
						<div>{$t(`country.${country.toUpperCase()}`)}</div>
					</button>
				{/each}
			</div>
		</div>
	</svelte:fragment>
	<div class="flex items-center flex-initial gap-1">
		<div class={`i-flag-${$languageStore.country.toLowerCase()} w-7 h-5 rounded-sm`}></div>
		<div class="whitespace-nowrap">
			{$languageStore.country} | {shippingMarkets.find((c) =>
				c.regions.nodes.find((n) => n.code === $languageStore.country)
			)?.currencySettings.baseCurrency.currencyCode}
		</div>
		<div class="i-sprenger-chevron-down w-5 h-5"></div>
	</div>
</GenericPicker>
