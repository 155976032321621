<script lang="ts">
	import { type SbBlokData, StoryblokComponent } from '@storyblok/svelte';

	export let blok: {
		items: SbBlokData[];
	};
</script>

<div class="w-full">
	{#each blok?.items || [] as blok}
		<StoryblokComponent {blok} />
	{/each}
</div>
