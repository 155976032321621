<script lang="ts">
	import type {
		ContentBlock14Props,
		ContentBranch,
		ImageStoryblok,
		LinkStoryblok
	} from '$lib/storyblok/types';
	import { createBreakpointStore } from '$lib/store/breakpoint.store';
	import type { ISbRichtext } from '@storyblok/svelte';
	import RichText from '$lib/components/Storyblok/RichText.svelte';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import ButtonPrimary from '$lib/components/Button/ButtonPrimary.svelte';
	import StoryblokImageOrVideo from '$lib/components/Image/StoryblokImageOrVideo.svelte';
	import { getImagePriorityBelowFold } from '$lib/utils/image';

	export let blok: ContentBlock14Props;
	export let position: number = 0;
	$: isBelowFold = position > 0;
	let className = '';

	export { className as class };

	const isMobile = createBreakpointStore();

	let selectedItem: number | undefined = undefined;

	$: data = (() => {
		const newData: Array<{
			image: ImageStoryblok;
			content_branch: ContentBranch;
			title: string;
			text_content: ISbRichtext;
			link: LinkStoryblok;
		}> = [];
		if (blok.image_1) {
			newData.push({
				image: blok.image_1,
				content_branch: blok.content_branch_1,
				title: blok.title_1,
				text_content: blok.text_content_1,
				link: blok.link_1
			});
		}
		if (blok.image_2) {
			newData.push({
				image: blok.image_2,
				content_branch: blok.content_branch_2,
				title: blok.title_2,
				text_content: blok.text_content_2,
				link: blok.link_2
			});
		}
		if (blok.image_3) {
			newData.push({
				image: blok.image_3,
				content_branch: blok.content_branch_3,
				title: blok.title_3,
				text_content: blok.text_content_3,
				link: blok.link_3
			});
		}

		return newData;
	})();
</script>

<div class="flex flex-col py-11 md:py-16">
	<RichText class="pl-3 md:pl-20 mb-8 text-h-56 md:text-h-72 hyphens-auto" text={blok.headline} />
	<div class={classes('flex flex-row', className)}>
		{#each data as item, index}
			<div
				class={classes(
					'relative overflow-hidden flex h-96 md:h-[40rem] transition-all duration-700 ease-in-out cursor-pointer',
					selectedItem === index ? 'flex-3' : 'flex-1'
				)}
				on:click={() =>
					selectedItem === index ? (selectedItem = undefined) : (selectedItem = index)}
				role="none"
			>
				<div
					class="absolute top-0 w-full h-4 lg:h-[23px] z-20"
					style:background-color={`var(--special-${item.content_branch})`}
				/>
				{#if selectedItem == null && !$isMobile}
					<div
						class="absolute select-none top-0 right-0 left-0 bottom-0 flex items-center justify-center opacity-0 transition-opacity ease-in-out duration-700 hover:opacity-100 z-20 text-h-40 lg:text-h-56 xl:text-h-72"
						style:color={`var(--special-${item.content_branch})`}
					>
						{item.title}
					</div>
				{/if}

				{#if !!item.image?.filename}
					<StoryblokImageOrVideo
						src={item.image.filename}
						alt={item.image.alt}
						focus={item.image.focus}
						width={$isMobile ? 465 : 768}
						height={$isMobile ? 465 : 768}
						class="object-center object-cover w-full h-full"
						title={item.image.title}
						{...getImagePriorityBelowFold(isBelowFold)}
					/>
				{/if}
				{#if item.text_content && !$isMobile}
					<div
						class={classes(
							'z-10 h-full transition-all bg-white duration-700',
							selectedItem === index ? 'opacity-100 md:w-[25vw]' : 'w-0 opacity-0'
						)}
					>
						<div class="md:w-[25vw] px-8 py-20 h-full flex flex-col">
							<RichText
								class="text-p-16 flex h-full flex-none flex-col gap-4"
								text={item.text_content}
							/>
							{#if item.link}
								<a
									on:click={(e) => e.stopPropagation()}
									href={item.link.url}
									class="mt-auto relative z-20"
								>
									<ButtonPrimary text="Zu den Produkten" />
								</a>
							{/if}
						</div>
					</div>
				{/if}
			</div>
		{/each}
	</div>
	<div>
		<div class="flex-1 z-30 h-full transition-all">
			<div class="w-full px-8">
				{#if selectedItem != null}
					<RichText
						class="text-p-16 flex flex-col gap-4 pt-6 pb-10"
						text={data[selectedItem].text_content}
					/>
				{/if}
				{#if selectedItem != null && data[selectedItem].link}
					<ButtonPrimary href={data[selectedItem].link.url} text="Zu den Produkten" />
				{/if}
			</div>
		</div>
	</div>
</div>
