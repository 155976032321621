<script lang="ts">
	import { storyblokEditable } from '@storyblok/svelte';
	import StoryblokButton from '$lib/components/Button/StoryblokButton.svelte';
	import type { ContentBlock1Props } from '$lib/storyblok/types';
	import ImagePair from '$lib/components/Image/ImagePair.svelte';
	import RichText from '$lib/components/Storyblok/RichText.svelte';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { getImagePriorityBelowFold } from '$lib/utils/image';

	export let blok: ContentBlock1Props;

	$: button = blok.button_text
		? { text: blok.button_text, url: blok.button_link.url, className: 'w-full my-6' }
		: undefined;
	export let position: number = 0;
	$: isBelowFold = position > 0;
</script>

<div
	use:storyblokEditable={blok}
	class={classes(
		'flex flex-col-reverse w-full space-y-reverse md:space-y-8 md:flex-col box-x-wrapper py-11 md:py-16',
		blok.blue_background ? 'bg-accent-primary_005' : ''
	)}
>
	{#if blok.image_emphasis === 'right' && (blok.image1.filename || blok.shopTheLook1) && (blok.image2?.filename || blok.shopTheLook2 || blok.video_id)}
		<div class="space-y-6">
			<ImagePair
				shopTheLook1={blok.shopTheLook1}
				shopTheLook2={blok.shopTheLook2}
				image1={blok.image1}
				image2={blok.image2}
				image_1_parallax={blok.image_1_parallax}
				image_2_parallax={blok.image_2_parallax}
				breakpoint={768}
				layout="medium-medium"
				video_id1={blok.video_id}
				autoplay1={blok.autoplay}
				imageProps={getImagePriorityBelowFold(isBelowFold)}
			/>
			<div class="flex flex-row justify-end w-full h-full z-0">
				<div class="w-full space-y-4 md:w-1/2 md:pl-1.5">
					{#if blok.headline}
						<h3 class="text-h-24 lg:text-h-32 mb-6 hyphens-auto">{blok.headline}</h3>
					{/if}
					<RichText text={blok.text} />
					<div class="flex flex-col md:items-start relative z-30">
						{#if button}
							<StoryblokButton {...button} />
						{/if}
					</div>
				</div>
			</div>
		</div>
	{/if}
	{#if blok.image_emphasis === 'left' && (blok.image1.filename || blok.shopTheLook1) && (blok.image2?.filename || blok.shopTheLook2 || blok.video_id)}
		<div class="space-y-6">
			<ImagePair
				shopTheLook1={blok.shopTheLook2}
				shopTheLook2={blok.shopTheLook1}
				image_1_parallax={blok.image_2_parallax}
				image_2_parallax={blok.image_1_parallax}
				image1={blok.image2}
				image2={blok.image1}
				breakpoint={768}
				layout="medium-medium"
				video_id2={blok.video_id}
				autoplay2={blok.autoplay}
				imageProps={getImagePriorityBelowFold(isBelowFold)}
			/>
			<div class="flex flex-row justify-start w-full h-full z-0">
				<div class="w-full space-y-4 md:w-1/2 md:pl-1.5">
					{#if blok.headline}
						<h3 class="text-h-24 lg:text-h-32 mb-6 hyphens-auto">{blok.headline}</h3>
					{/if}
					<RichText text={blok.text} />
					<div class="flex flex-col md:items-start relative z-30">
						{#if button}
							<StoryblokButton {...button} />
						{/if}
					</div>
				</div>
			</div>
		</div>
	{/if}
</div>
