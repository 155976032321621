<script>
	import { getTranslate, T } from '@tolgee/svelte';

	const { t } = getTranslate();
</script>

<div class="flex flex-col">
	<div class="text-h-12 mb-1.5">
		<T keyName="footer.contact" />
	</div>
	<div class="text-p-12">
		{@html $t('footer.address')}
	</div>
</div>
