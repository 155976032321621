import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/TwoProductsQuery'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class TwoProductsQueryStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "TwoProductsQueryStore",
			variables: false,
		})
	}
}

export async function load_TwoProductsQuery(params) {
	await initClient()

	const store = new TwoProductsQueryStore()

	await store.fetch(params)

	return {
		TwoProductsQuery: store,
	}
}
