<script lang="ts">
	// import type { HistoryBlockProps } from '$lib/storyblok/types';
	import { onMount, onDestroy, tick } from 'svelte';
	import { storyblokEditable } from '@storyblok/svelte';
	import { hasRichText } from '$lib/utils/richText';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { browser } from '$app/environment';
	import { writable } from 'svelte/store';
	import slug from 'slug';

	import HistoryCard from '$lib/components/Card/HistoryCard.svelte';
	import { createBreakpointStore } from '$lib/store/breakpoint.store';

	export let blok; //: HistoryBlockProps;
	let activeYear = '';
	let indicator: HTMLDivElement | null = null;
	let yearLinks: HTMLAnchorElement[] = [];
	let resizeObserver: ResizeObserver;
	let currentIndex = writable(0);
	const mobileStore = createBreakpointStore();

	const handleAnchorClick = (event) => {
		const anchor = event.target as HTMLAnchorElement;
		if (!anchor) return;
		const href = anchor.getAttribute('href');
		if (!href) return;
		const scrollId = href.replace('#', '');
		const scrollElem =
			document.getElementById(scrollId) || document.getElementById(scrollId.split('-')?.[0]);
		if (!scrollElem) return;

		const parent = scrollElem.parentElement;

		if (parent) {
			const offsetLeft = scrollElem.offsetLeft;
			const parentWidth = parent.clientWidth;
			const scrollPosition = isMobile
				? offsetLeft - parentWidth / 2 + scrollElem.clientWidth / 2
				: offsetLeft - parentWidth / 2 + scrollElem.clientWidth / 2 + scrollElem.clientWidth;

			parent.scrollTo({
				left: scrollPosition,
				behavior: 'smooth'
			});
		}
	};

	const updateIndicator = () => {
		const activeLink = yearLinks.find((link) => link.hash.includes(`${activeYear}`));
		if (!activeLink || !indicator) return;
		const rect = activeLink.getBoundingClientRect();

		if (!activeLink?.parentElement) return;
		const containerRect = activeLink.parentElement.getBoundingClientRect();
		indicator.style.width = `${rect.width}px`;
		indicator.style.transform = `translateX(${rect.left - containerRect.left}px)`;
	};

	const handleScroll = () => {
		const yearElements = blok.historyLines.map((historyLine) =>
			document.getElementById(
				slug(
					hasRichText(historyLine?.description)
						? historyLine.year
						: historyLine?.historyCards?.[0]?.year
				)
			)
		);
		for (const elem of yearElements) {
			if (elem) {
				const rect = elem.getBoundingClientRect();
				const margins =
					window.innerWidth >= 1024
						? (window.innerWidth - 1664) / 2 + window.innerWidth * 0.05
						: window.innerWidth * 0.05;
				const elemCenter = rect.left + rect.width / 2 - margins;
				const isVisible = elemCenter >= 0 && elemCenter <= window.innerWidth;
				if (isVisible) {
					activeYear = elem.getAttribute('id');
					updateIndicator();
					break;
				}
			}
		}
	};

	const calculateIndex = (mainIndex: number, subIndex = 0): number => {
		let count = 0;
		for (let i = 0; i < mainIndex; i++) {
			count += 1; // for the main history line
			count += blok.historyLines[i].historyCards ? blok.historyLines[i].historyCards.length : 0; // add sub cards
		}
		return count + subIndex;
	};

	onMount(() => {
		handleScroll();
		resizeObserver = new ResizeObserver(() => updateIndicator());
		yearLinks.forEach((link) => resizeObserver.observe(link));

		window.addEventListener('resize', updateIndicator);
	});

	onDestroy(() => {
		if (resizeObserver) {
			resizeObserver.disconnect();
		}
		if (browser) {
			window.removeEventListener('resize', updateIndicator);
		}
	});

	function hasPopupAtIndex(index: number): boolean {
		return (
			blok?.historyLines?.flatMap((line) => [line, ...line.historyCards]).at(index).component ===
			'historyCard'
		);
	}

	$: totalCards = blok?.historyLines?.reduce(
		(acc, line) => acc + 1 + (line.historyCards ? line.historyCards.length : 0),
		0
	);

	$: isMobile = $mobileStore;
</script>

<section class="w-full overflow-x-hidden pt-10 lg:pt-65 pb-35 lg:pb-58">
	<div class="flex flex-col gap-y-10 lg:gap-y-20 w-full" use:storyblokEditable={blok}>
		<div class="box-x-wrapper flex flex-col gap-10 lg:gap-20 w-full">
			{#if blok?.headline}
				<h2 class="text-h-32 lg:text-h-72 lg:w-8/12">{blok.headline}</h2>
			{/if}
			<!-- Tabs -->
			<div class="flex flex-col relative">
				{#if blok?.historyLines?.length}
					<div
						class="
							flex items-center scrollbar-hide overflow-x-auto lg:overflow-x-hidden
							before:content-[''] before:shrink-0 lg:before:w-[calc((100vw-104rem-15.5px)/2)] xl:before:w-0
							after:content-[''] after:shrink-0 after:w-3 lg:after:w-[calc((100vw-104rem-15.5px)/2)] xl:after:w-0
						"
					>
						{#each blok.historyLines as historyLine}
							<a
								bind:this={yearLinks[blok.historyLines.indexOf(historyLine)]}
								href={`#${slug(historyLine.year)}`}
								on:click|preventDefault={handleAnchorClick}
								class={classes(
									slug(historyLine.year).includes(activeYear)
										? 'border-b-accent-primary text-accent-primary'
										: 'border-b-transparent',
									'year-link text-h-24 pb-4 first:ml-0 last:mr-0 mx-5 lg:mx-10 border-b-5 hover:border-b-accent-primary hover:no-underline whitespace-nowrap transition-all duration-300 ease-in-out'
								)}
							>
								{historyLine.year}
							</a>
						{/each}
						<div
							bind:this={indicator}
							class="absolute bottom-0 h-4.5px bg-accent-primary transition-all duration-300"
						></div>
					</div>
					<div class="h-5px w-full bg-dark-primary bg-opacity-12 absolute bottom-0 left-0" />
				{/if}
			</div>
		</div>

		<!-- Content -->
		{#if blok?.historyLines?.length}
			<!-- 
			- 100vw: Full viewport width
			- 104rem: Max-width of the container
			- 15.5px: Approximate scrollbar width
			- (100vw - 104rem - 15.5px) / 2: The remaining space on either side of the centered container, divided by 2 (aka. the mx-auto)
		 -->
			<ul
				on:scroll={handleScroll}
				class="
					flex overflow-x-auto scrollbar-hide snap-x snap-mandatory
					before:content-[''] before:shrink-0 before:w-3 md:pl-5% lg:before:w-[calc((100vw-104rem-15.5px)/2)]
					after:content-[''] after:shrink-0 after:w-3 md:pr-5% lg:after:w-[calc((100vw-104rem-15.5px)/2)]
				"
			>
				{#each blok.historyLines as historyLine, index (index)}
					<HistoryCard
						content={historyLine}
						index={calculateIndex(index)}
						updateCurrentIndex={(newIndex) => currentIndex.set(newIndex)}
						{currentIndex}
						{totalCards}
						{hasPopupAtIndex}
					/>
					{#if historyLine?.historyCards?.length}
						{#each historyLine.historyCards as historyCard, cardIndex (cardIndex)}
							<!-- +1 because the main history line also counts as a card -->
							<HistoryCard
								content={historyCard}
								hasPopup={true}
								index={calculateIndex(index, cardIndex + 1)}
								updateCurrentIndex={(newIndex) => currentIndex.set(newIndex)}
								{currentIndex}
								{totalCards}
								{hasPopupAtIndex}
							/>
						{/each}
					{/if}
				{/each}
			</ul>
		{/if}
	</div>
</section>
