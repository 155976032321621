<script lang="ts">
	import type { ContentBlock3Props } from '../../storyblok/types';
	import StoryblokButton from '$lib/components/Button/StoryblokButton.svelte';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import RichText from '$lib/components/Storyblok/RichText.svelte';

	export let blok: ContentBlock3Props;

	$: button = blok.button_text
		? { text: blok.button_text, url: blok.button_link.url, className: 'w-full my-6' }
		: undefined;
</script>

<div
	class={classes(
		'md:flex md:flex-col md:items-start w-full md:pl-1.5',
		blok.text_emphasis === 'center' ? 'md:w-2/3' : 'md:w-1/2'
	)}
>
	<span class="text-h-16">{blok.subtitle}</span>
	{#if blok.headline}
		<h3 class="text-h-24 lg:text-h-32 mb-6 hyphens-auto">{blok.headline}</h3>
	{/if}
	<RichText text={blok.text} />
	<div class="flex flex-col items-start">
		{#if button}
			<StoryblokButton {...button} />
		{/if}
	</div>
</div>
