<script lang="ts">
	import { customerSessionDrawer } from '$lib/store/customerSessionDrawer.store';
	import { getTranslate } from '@tolgee/svelte';
	import Button from '$lib/components/Button/Button.svelte';

	const { t } = getTranslate();

	$: headerTitle = {
		default: $t('customerSession.drawer.default.title'),
		login: $t('customerSession.drawer.login.title'),
		register: $t('customerSession.drawer.register.title'),
		reset: $t('customerSession.drawer.reset.title')
	};
	$: headerSubLinkLabel = {
		default: $t('customerSession.drawer.default.subLinkLabel'),
		login: $t('customerSession.drawer.login.subLinkLabel'),
		register: $t('customerSession.drawer.register.subLinkLabel'),
		reset: $t('customerSession.drawer.reset.subLinkLabel')
	};
	$: headerSubmitAction = {
		default: 'register',
		login: 'register',
		register: 'login',
		reset: 'login'
	};
</script>

<div class="flex flex-col w-full pb-5 pt-16">
	<div class="flex flex-row items-center">
		<div class="py-2 text-white text-h-24">{headerTitle[$customerSessionDrawer.sessionType]}</div>
		{#if $customerSessionDrawer.sessionType === 'default'}
			<Button
				class="text-accent-primary border-2 border-transparent uppercase bg-white hover:bg-accent-primary hover:text-white hover:border-white px-6 ml-auto md:mr-[5.125rem]"
				text={$t('customerSession.drawer.default.submitLabel')}
				on:click={() => customerSessionDrawer.setSessionType('login')}
			/>
		{/if}
	</div>
	<hr class="my-5" />
	<div class="flex flex-row items-center">
		<div class="text-white text-h-14">
			{headerSubLinkLabel[$customerSessionDrawer.sessionType]}
		</div>
		<button
			class="flex items-center flex-initial w-10 h-10 ml-auto md:mr-[5.125rem] border-2 border-transparent bg-white text-accent-primary hover:bg-accent-primary hover:text-white hover:border-white rounded-full justify-centers"
			on:click={() =>
				customerSessionDrawer.setSessionType(
					headerSubmitAction[$customerSessionDrawer.sessionType]
				)}
		>
			<div class="i-sprenger-chevron-right flex-1 w-5 h-5" />
		</button>
	</div>
</div>
