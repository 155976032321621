<script lang="ts">
	export let title: string;
	export let instagramLink: string | undefined = undefined;
	export let facebookLink: string | undefined = undefined;
	export let youtubeLink: string | undefined = undefined;
	let className = '';
	export { className as class };
</script>

<div class={className}>
	<span>{title}</span>
	<div class="flex gap-2 items-center mt-1">
		{#if instagramLink}
			<a href={instagramLink}>
				<div class="hover:text-white hover:cursor-pointer i-sprenger-instagram w-6 h-6" />
			</a>
		{/if}
		{#if facebookLink}
			<a href={facebookLink}>
				<div class="hover:text-white hover:cursor-pointer i-sprenger-facebook w-4.5 h-4.5" />
			</a>
		{/if}
		{#if youtubeLink}
			<a href={youtubeLink}>
				<div class="hover:text-white hover:cursor-pointer i-sprenger-youtube w-6 h-6" />
			</a>
		{/if}
	</div>
</div>
