<script lang="ts">
	import type { ContentBlock6Props } from '$lib/storyblok/types';
	import { Breakpoint, createBreakpointStore } from '$lib/store/breakpoint.store';
	import { storyblokEditable } from '@storyblok/svelte';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import StoryblokImageOrVideo from '$lib/components/Image/StoryblokImageOrVideo.svelte';
	import { primaryColorDict } from '$lib/color';
	import StoryblokButton from '$lib/components/Button/StoryblokButton.svelte';
	import RichText from '$lib/components/Storyblok/RichText.svelte';
	import { getImagePriorityBelowFold } from '$lib/utils/image';

	export let blok: ContentBlock6Props;
	export let position: number = 0;
	$: isBelowFold = position > 0;
	const wrapperMaxWidth = 1280;
	const isSmallerViewPort = createBreakpointStore(Breakpoint['2xl']);
	const isMobile = createBreakpointStore(Breakpoint.md);

	let blokTitleHtml = '';
	let titleAsNormalString = '';

	const applyTitle = (blok: ContentBlock6Props, viewPortSmaller: boolean) => {
		if (blok.titleRich) {
			let titleAsNormalStringTemp = '';
			const blockTitleElementContent: string[] = [];
			for (const paragraph of blok.titleRich.content) {
				const paragraphText = paragraph?.content?.[0]?.text ?? '';
				titleAsNormalStringTemp = titleAsNormalStringTemp += paragraph.content
					? `${paragraphText} `
					: ' ';
				const paragraphTextCleaned = paragraphText.trimEnd();
				let paragraphTextNoSpaces: Array<string> = [];

				if (paragraphTextCleaned.match(/[.,]$/)) {
					if (paragraphTextCleaned.includes(' ')) {
						paragraphTextNoSpaces = paragraphTextCleaned.split(' ');
						const lastParagraphTextElementIndex = paragraphTextNoSpaces.length - 1;

						paragraphTextNoSpaces.forEach((paragraphTextPart, index) => {
							if (
								index === lastParagraphTextElementIndex &&
								paragraphTextNoSpaces[lastParagraphTextElementIndex].length <= 6
							) {
								blockTitleElementContent.push(
									`<span style="white-space: nowrap">${paragraphTextNoSpaces[lastParagraphTextElementIndex]}&nbsp;<br /></span>`
								);
							} else {
								blockTitleElementContent.push(`${paragraphTextPart}&nbsp;`);
							}
						});
					} else {
						if (paragraphTextCleaned.length <= 6) {
							blockTitleElementContent.push(
								`<span style="white-space: nowrap">${paragraphTextCleaned}&nbsp;<br /></span>`
							);
						} else {
							const paragraphTextMain = paragraphTextCleaned.slice(
								0,
								paragraphTextCleaned.length - 2
							);
							const paragraphTextEnding = paragraphTextCleaned.slice(
								paragraphTextCleaned.length - 2
							);
							blockTitleElementContent.push(paragraphTextMain);
							blockTitleElementContent.push(
								`<span style="white-space: nowrap">${paragraphTextEnding}<br /></span>`
							);
						}
					}
				} else if (paragraphTextCleaned.includes(' ')) {
					paragraphTextNoSpaces = paragraphTextCleaned.split(' ');

					paragraphTextNoSpaces.forEach((paragraphTextPart) => {
						if (paragraphTextPart.length <= 6) {
							blockTitleElementContent.push(
								`<span style="white-space: nowrap">${paragraphTextPart}&nbsp;<br /></span>`
							);
						} else {
							blockTitleElementContent.push(`${paragraphText} `);
						}
					});
				} else if (paragraphText.length <= 6) {
					blockTitleElementContent.push(
						!viewPortSmaller
							? `<span style="white-space: nowrap">${paragraphText}&nbsp;<br /></span>`
							: `<span style="word-break: keep-all">${paragraphText}&nbsp;</span>`
					);
				} else if (paragraphText.length > 10 && paragraphText.length < 13) {
					blockTitleElementContent.push(
						`<span style="${
							!viewPortSmaller ? 'white-space: nowrap;' : 'word-break: break-word;'
						}">${paragraphText}<br /></span>`
					);
				} else {
					blockTitleElementContent.push(`${paragraphText} `);
				}
			}
			blokTitleHtml = `
              ${blockTitleElementContent
								.map((element) => `<p class="title" style="display: inline-block">${element}</p>`)
								.join('')}`;
			titleAsNormalString = titleAsNormalStringTemp;
		}
	};

	$: applyTitle(blok, $isSmallerViewPort);

	let windowWidth = 0;
</script>

<svelte:window bind:innerWidth={windowWidth} />
<div
	use:storyblokEditable={blok}
	class={classes(
		'sm:pb-36 lg:pb-48 mb-12 !mt-0',
		blok.add_padding ? 'pt-11 md:pt-16' : '',
		blok.blue_background && 'bg-accent-primary_005'
	)}
>
	<div class="relative flex flex-col items-center w-full">
		<div
			class={classes(
				'flex flex-col w-full min-h-[46rem]',
				blok.image_emphasis === 'right' ? 'sm:flex-row' : 'sm:flex-row-reverse'
			)}
		>
			{#if blok.bg_image.filename || blok.video_id || blok.shopTheLook}
				<div
					class="relative z-10 w-full aspect-square sm:w-1/2 sm:aspect-auto lg:w-8/12"
					style:width={$isMobile && windowWidth >= wrapperMaxWidth
						? `calc((100vw - ${wrapperMaxWidth}px)/2 + (7 / 12) *  ${wrapperMaxWidth}px)`
						: undefined}
					style:perspective="8px"
					style:perspective-origin="50%"
				>
					<StoryblokImageOrVideo
						filename={blok.bg_image?.filename}
						placeholder="blur"
						alt={blok.bg_image?.alt}
						sizes={$isMobile ? '100vw' : '66vw'}
						focus={blok.bg_image?.focus}
						parallax={blok.image_parallax}
						video_id={blok.video_id}
						autoplay={blok.autoplay}
						shopTheLook={blok.shopTheLook}
						class="object-cover w-full h-full absolute"
						title={blok.bg_image?.title}
						{...getImagePriorityBelowFold(isBelowFold)}
					/>
					{#if blok.floating_image.filename}
						<div
							class={classes(
								'absolute bottom-0 translate-y-1/2 sm:w-1/2 pointer-events-none aspect-square max-md:flex max-md:justify-center max-md:left-1/2 max-md:-translate-x-1/2',
								blok.image_emphasis === 'right' && 'md:right-0'
							)}
							style:width={$isMobile ? `${blok.mobileProductImageSize?.value ?? 100}%` : undefined}
						>
							<StoryblokImageOrVideo
								{...blok.floating_image}
								sizes="256px, 512px"
								class="absolute w-full h-full"
								{...getImagePriorityBelowFold(isBelowFold)}
							/>
						</div>
					{/if}
				</div>
			{/if}

			<div
				class={`flex flex-col justify-center z-0 px-4 space-y-6 pt-32 sm:pt-0 ${
					blok.content_branch === 'noBranding' ? '' : 'border-t-24'
				} sm:w-1/2 sm:space-y-0 sm:px-0 lg:w-4/12`}
				style:border-top-color={primaryColorDict[blok.content_branch] || primaryColorDict.dog}
				style:width={$isMobile && windowWidth >= wrapperMaxWidth
					? `calc((100vw - ${wrapperMaxWidth}px)/2 + (5 / 12) *  ${wrapperMaxWidth}px)`
					: undefined}
			>
				<div
					class="flex flex-col items-center space-y-8 sm:items-start sm:pr-wrapper sm:pl-10"
					style:max-width={$isMobile && windowWidth >= wrapperMaxWidth
						? `calc((5 / 12) * ${wrapperMaxWidth}px)`
						: undefined}
				>
					<div class="space-y-6 max-w-full">
						<h4
							class="text-h-56 lg:text-h-64 xl:text-h-72"
							style={blok.use_richtext
								? 'hyphens: auto; word-break: break-word;'
								: 'hyphens: auto;'}
						>
							{#if !$isMobile && blok.use_richtext}
								<div style="hyphens: auto;">{@html blokTitleHtml}</div>
							{:else if blok.title}
								{blok.title}
							{:else}
								{titleAsNormalString}
							{/if}
						</h4>
						{#if blok.use_richtext}
							{#if blok.descriptionRich}
								<RichText text={blok.descriptionRich}></RichText>
							{/if}
						{:else if blok.description}
							<p>{blok.description}</p>
						{/if}
					</div>
					{#if blok.button_text && blok.button_link}
						<StoryblokButton text={blok.button_text} url={blok.button_link.url} />
					{/if}
				</div>
			</div>
		</div>
	</div>
</div>
