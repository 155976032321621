<script lang="ts">
	import { storyblokEditable } from '@storyblok/svelte';
	import type { MaterialAndCareBlockProps } from '$lib/storyblok/types';

	export let blok: MaterialAndCareBlockProps;
</script>

<div use:storyblokEditable={blok}>
	<dt class="text-h-14">{blok.title}</dt>
	<dd class="mt-2 text-p-14 text-dark-primary">{blok.description}</dd>
</div>
