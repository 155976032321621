<script lang="ts">
	import { page } from '$app/stores';
	import { storyblokEditable } from '@storyblok/svelte';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { scrollNavBarHandler } from '$lib/store/scrollNavBarHandler.store';
	import { onMount } from 'svelte';

	let selected = 0;
	let isOverflowing = false;
	let overflowItemsInterval: number;

	$: blok = $page.data?.infoBar?.content;

	let infoBar: HTMLElement;

	const evaluateOverflow = () => {
		if (!infoBar) {
			return;
		}
		const { scrollWidth, clientWidth } = infoBar;
		isOverflowing = scrollWidth > clientWidth;
		if (isOverflowing && !overflowItemsInterval) {
			overflowItemsInterval = setInterval(() => {
				selected = (selected + 1) % (blok?.parts?.length ?? 0);
			}, 6000);
		} else {
			clearInterval(overflowItemsInterval);
			overflowItemsInterval = undefined;
		}
	};

	onMount(() => {
		evaluateOverflow();
		window.addEventListener('resize', evaluateOverflow);

		return () => {
			window.removeEventListener('resize', evaluateOverflow);
		};
	});
</script>

{#if blok}
	<div
		bind:this={infoBar}
		use:storyblokEditable={blok}
		class={classes(
			'w-full min-h-[42px] relative flex px-4 gap-1 items-center justify-center md:justify-evenly gap-4 text-h-12 whitespace-nowrap',
			!$scrollNavBarHandler.isColorfulBar ? 'text-white bg-accent-primary' : 'text-black bg-white'
		)}
	>
		{#each blok.parts as part}
			{#if part.link?.url}
				<a href={part.link.url} target={part.link.target}>{part.text}</a>
			{:else}
				<div>
					{part.text}
				</div>
			{/if}
		{/each}
		{#if isOverflowing}
			<div
				class={classes(
					'absolute w-full h-full',
					!$scrollNavBarHandler.isColorfulBar
						? 'text-white bg-accent-primary'
						: 'text-black bg-white'
				)}
			>
				<div class="relative w-full h-full">
					{#each blok.parts as part, i}
						<!--
						 				class:translate-x-full={i - 1 === currentIndex ||
						(i === 0 && currentIndex === blok.slides.length - 1)}
					class:-translate-x-full={i + 1 === currentIndex ||
						(i === blok.slides.length - 1 && currentIndex === 0)}
						 -->
						{@const isIncoming =
							i - 1 === selected || (i === 0 && selected === blok.parts.length - 1)}
						{@const isOutgoing =
							i + 1 === selected || (i === blok.parts.length - 1 && selected === 0)}
						{#if part.link?.url}
							<a
								class={classes(
									'absolute top-1/2 transform transition -translate-y-1/2 left-1/2 duration-3000',
									i === selected ? '-translate-x-1/2 opacity-100 delay-500' : 'opacity-0'
								)}
								class:-translate-x-64={isIncoming}
								class:translate-x-64={isIncoming}
								href={part.link.url}
								target={part.link.target}>{part.text}</a
							>
						{:else}
							<div
								class={classes(
									'absolute top-1/2 transform transition -translate-y-1/2 left-1/2 duration-3000',
									i === selected ? '-translate-x-1/2 opacity-100 delay-500' : 'opacity-0'
								)}
								class:-translate-x-64={isIncoming}
								class:translate-x-64={isOutgoing}
							>
								{part.text}
							</div>
						{/if}
					{/each}
				</div>
			</div>
		{/if}
	</div>
{/if}
