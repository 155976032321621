<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';
	import type { ImageStoryblok, ShopTheLookProps } from '$lib/storyblok/types';
	import StoryblokImageOrVideo from '$lib/components/Image/StoryblokImageOrVideo.svelte';
	import { createBreakpointStore } from '$lib/store/breakpoint.store';

	export let className: string;
	// export let breakpoint = 768;
	export let layout: 'medium-medium' | 'small-big';
	export let image1: ImageStoryblok | undefined;
	export let image2: ImageStoryblok | undefined;
	export let video_id1: string | undefined;
	export let autoplay1: boolean | undefined;
	export let video_id2: string | undefined;
	export let autoplay2: boolean | undefined;
	export let image_1_parallax: boolean | undefined;
	export let image_2_parallax: boolean | undefined;
	export let shopTheLook1: ShopTheLookProps | undefined;
	export let shopTheLook2: ShopTheLookProps | undefined;
	export let imageProps = {};

	const LAYOUTS = {
		'medium-medium': {
			className1: 'w-1/2 mr-3',
			className2: 'w-1/2',
			marginBottom1: '-50%',
			paddingBottom1: '50%',
			paddingBottom2: '50%'
		},
		'small-big': {
			className1: 'w-1/3 mr-3',
			className2: 'w-2/3',
			marginBottom1: '-33.33333%',
			paddingBottom1: '66.66666%',
			paddingBottom2: '33.33333%'
		}
	};

	$: currentLayout = LAYOUTS[layout] || LAYOUTS['medium-medium'];
	const isMobile = createBreakpointStore();
	$: reachedBreakpoint = !$isMobile; // $screenSize > breakpoint;
</script>

{#if reachedBreakpoint}
	<div
		class={classes('relative w-full pb-full', className)}
		style:margin-bottom={currentLayout.marginBottom1}
	>
		<div class="absolute top-0 left-0 flex flex-row w-full h-full">
			<div class={currentLayout.className1} style:padding-bottom={currentLayout.paddingBottom1}>
				<div class="relative w-full h-full bg-radial-gradient-product-image">
					<StoryblokImageOrVideo
						{...imageProps}
						shopTheLook={shopTheLook1}
						filename={image1?.filename}
						focus={image1?.focus}
						alt={image1?.alt}
						parallax={image_1_parallax}
						sizes="50vw"
						layout="fill"
						class="object-cover h-full absolute w-full"
						video_id={video_id1}
						autoplay={autoplay1}
						title={image1?.title}
					/>
				</div>
			</div>

			<div class={currentLayout.className2} style:padding-bottom={currentLayout.paddingBottom2}>
				<div class="relative w-full h-full bg-radial-gradient-product-image">
					<StoryblokImageOrVideo
						{...imageProps}
						shopTheLook={shopTheLook2}
						filename={image2?.filename}
						focus={image2?.focus}
						alt={image2?.alt}
						parallax={image_2_parallax}
						sizes="50vw"
						layout="fill"
						class="object-cover h-full absolute w-full"
						video_id={video_id2}
						autoplay={autoplay2}
						title={image2?.title}
					/>
				</div>
			</div>
		</div>
	</div>
{:else}
	<div class={classes('relative w-full', className)}>
		<div class="relative w-full mt-4 bg-radial-gradient-product-image aspect-square">
			<StoryblokImageOrVideo
				{...imageProps}
				shopTheLook={shopTheLook1}
				filename={image1?.filename}
				focus={image1?.focus}
				alt={image1?.alt}
				parallax={image_1_parallax}
				sizes="75vw"
				layout="fill"
				class="object-cover absolute w-full h-full"
				video_id={video_id1}
				autoplay={autoplay1}
				title={image1?.title}
			/>
		</div>
		<div class="relative w-full mt-4 bg-radial-gradient-product-image aspect-square">
			<StoryblokImageOrVideo
				{...imageProps}
				shopTheLook={shopTheLook2}
				filename={image2?.filename}
				focus={image2?.focus}
				alt={image2?.alt}
				parallax={image_2_parallax}
				sizes="75vw"
				layout="fill"
				class="object-cover absolute w-full h-full"
				video_id={video_id2}
				autoplay={autoplay2}
				title={image2?.title}
			/>
		</div>
	</div>
{/if}
