<script lang="ts">
	import { page } from '$app/stores';
	import { collectionSideDrawer } from '$lib/store/collectionSideDrawer.store';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { get } from 'svelte/store';
	import CollectionTreeNavigator from '$lib/components/Drawer/CollectionTreeNavigator.svelte';
	import CollectionTreeBottom from '$lib/components/Drawer/CollectionTreeBottom.svelte';

	import { languageStore } from '$lib/store/language.store';
	import { shippingMarkets } from '$lib/utils/shippingCountries';
	import { languageHelperStore, PickerState } from '$lib/store/languageHelper.store';
	import type { CollectionTree } from '$lib/utils/collectionTree';

	let delayedExpanded = get(collectionSideDrawer).isExpanded;

	$: {
		if ($collectionSideDrawer.isExpanded) {
			delayedExpanded = true;
		} else {
			setTimeout(() => {
				delayedExpanded = get(collectionSideDrawer).isExpanded;
			}, 500);
		}
	}

	let currentTree = [$page.data.collectionTree];

	const updateFromLayout = (tree: CollectionTree) => {
		currentTree = [tree];
	};
	$: updateFromLayout($page.data.collectionTree);
</script>

<div
	class={classes(
		'fixed flex flex-row w-full h-full left-0 top-0 transition transform duration-500',
		$collectionSideDrawer.isExpanded
			? 'z-30'
			: '-translate-y-full md:translate-y-0 md:-translate-x-full',
		delayedExpanded ? 'z-30' : '-z-10 invisible'
	)}
>
	<div class="relative w-full block md:hidden">
		<CollectionTreeNavigator
			initialTreeClasses="md:max-w-md"
			followingTreeClasses="md:max-w-sm"
			bind:currentTree
			on:close={() => {
				collectionSideDrawer.setExpanded(false);
				updateFromLayout($page.data.collectionTree);
			}}
		>
			<svelte:fragment slot="bottom">
				<CollectionTreeBottom bind:currentTree />
				<button
					on:click={() => languageHelperStore.setPickerState(PickerState.CHOOSE_COUNTRY)}
					class="flex items-center flex-initial gap-3"
				>
					<div class={`i-flag-${$languageStore.country.toLowerCase()} w-9 h-6`}></div>
					<div class="w-16">
						{$languageStore.country} | {shippingMarkets.find((c) =>
							c.regions.nodes.find((n) => n.code === $languageStore.country)
						)?.currencySettings.baseCurrency.currencyCode}
					</div>
					<div class="i-sprenger-chevron-down w-5 h-5 ml-4"></div>
				</button>
			</svelte:fragment>
		</CollectionTreeNavigator>
	</div>
</div>
