<script lang="ts">
	import { field, form } from 'svelte-forms';
	import { email, required } from 'svelte-forms/validators';
	import FormFieldTextInput from '$lib/components/Form/FormFieldTextInput.svelte';
	import { getTranslate } from '@tolgee/svelte';
	import { customerSessionDrawer } from '$lib/store/customerSessionDrawer.store';
	import ButtonPrimary from '$lib/components/Button/ButtonPrimary.svelte';
	import { get } from 'svelte/store';
	import { customerSessionStore } from '$lib/store/customerSession.store';
	import { goto } from '$app/navigation';
	import { page } from '$app/stores';
	import { resolveRoute } from '$app/paths';

	const emailField = field('email', '', [required(), email()]);
	const formData = form(emailField);
	let isLoading = false;
	let succeeded = false;
	let fetchErrors = [];

	const { t } = getTranslate();

	const onSubmit = async () => {
		await formData.validate();
		if (get(formData).valid) {
			isLoading = true;
			try {
				// await customerSessionStore.login(get(emailField).value, get(passwordField).value);
				await customerSessionStore.requestPasswordReset(get(emailField).value);
				succeeded = true;
			} catch (e) {
				if (Array.isArray(e)) {
					fetchErrors = e;
				} else {
					throw e;
				}
			}
			isLoading = false;
		}
	};
</script>

<form on:submit|preventDefault={onSubmit} class="flex flex-col gap-6">
	<FormFieldTextInput
		label={$t('customer.email')}
		helperText={$t('forgotPassword.helper')}
		bind:value={$emailField.value}
		errors={$formData.errors}
		name="email"
		type="text"
	/>
	{#if fetchErrors.length}
		<div class="text-special-danger">
			{#each fetchErrors.filter((e) => !!e.code) as err}
				<div>{$t(`customerErrorCodeMapping.${err.code}`)}</div>
			{/each}
		</div>
	{/if}
	<div class="flex">
		{#if succeeded}
			<h5 class="mt-2 text-center text-accent-primary">{$t('forgotPassword.emailSent')}</h5>
		{:else}
			<ButtonPrimary
				class="w-full"
				type="submit"
				loading={isLoading}
				text={$t('forgotPassword.submitButton')}
			/>
		{/if}
	</div>
</form>
