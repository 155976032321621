<script lang="ts">
	import { storyblokEditable } from '@storyblok/svelte';
	import type { QAndABlockProps } from '$lib/storyblok/types';
	import RichText from './RichText.svelte';

	export let blok: QAndABlockProps;
</script>

<div use:storyblokEditable={blok}>
	<dt class="text-lg">{blok.question}</dt>
	<dd class="mt-2 text-base text-gray-500">
		<RichText text={blok.answer} />
	</dd>
</div>
