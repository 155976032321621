<script lang="ts">
	import {
		aboutNavMenuList,
		faqNavMenuList,
		menuCustomerLinkList,
		menuHelpLinkList
	} from '$lib/utils/links';
	import { page } from '$app/stores';
	import { getTranslate, T } from '@tolgee/svelte';
	import { resolveRoute } from '$app/paths';
	import { collectionSideDrawer } from '$lib/store/collectionSideDrawer.store';
	import type { CollectionTree } from '$lib/utils/collectionTree';

	export let currentTree: CollectionTree[];
	const { t } = getTranslate();
</script>

<hr class="border-light-quaternary" />
<button
	on:click={() => (currentTree = [...currentTree.slice(0, 1), faqNavMenuList])}
	class="text-h-16 flex justify-between"
>
	<T keyName={faqNavMenuList.title} />

	<span class="i-sprenger-chevron-right w-6 h-6"></span>
</button>
<button
	on:click={() => (currentTree = [...currentTree.slice(0, 1), aboutNavMenuList])}
	class="text-h-16 flex justify-between"
>
	<T keyName={aboutNavMenuList.title} />

	<span class="i-sprenger-chevron-right w-6 h-6"></span>
</button>
{#each menuHelpLinkList as { link, title, isExternal }}
	<a
		on:click={() => collectionSideDrawer.setExpanded(false)}
		href={isExternal
			? link
			: resolveRoute(link, {
					...$page.params
				})}
		class="text-h-16">{$t(title)}</a
	>
{/each}
<hr class="border-light-quaternary" />
{#each menuCustomerLinkList as { link, title, isExternal }}
	<a
		on:click={() => collectionSideDrawer.setExpanded(false)}
		href={isExternal
			? link
			: resolveRoute(link, {
					...$page.params
				})}>{$t(title)}</a
	>
{/each}
