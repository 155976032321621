<script lang="ts">
	import ButtonPrimary from '$lib/components/Button/ButtonPrimary.svelte';

	export let text: string;
	export let url: string | undefined = undefined;
	let className = '';

	export { className as class };
</script>

{#if url}
	<a href={url}>
		<ButtonPrimary class={className} {text} />
	</a>
{:else}
	<ButtonPrimary class={className} {text} />
{/if}
