<script lang="ts">
	import { b2bSessionDrawer } from '$lib/store/b2bSessionDrawer.store';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { clickOutside } from '$lib/utils/clickOutside';
	import B2BSessionDrawerHeader from './B2BSessionDrawerHeader.svelte';
</script>

{#if $b2bSessionDrawer.isExpanded}
	<div
		on:click={() => b2bSessionDrawer.setIsExpanded(false)}
		on:keydown={(e) => {
			if (e.key === 'Escape') {
				b2bSessionDrawer.setIsExpanded(false);
			}
		}}
		role="button"
		tabindex="0"
		class="absolute left-0 top-0 w-full h-full"
	></div>
{/if}
<div
	use:clickOutside
	on:click_outside={() => b2bSessionDrawer.setIsExpanded(false)}
	class={classes(
		'fixed right-0 top-0 transform transition duration-500 md:min-w-sm md:max-w-sm w-full overflow-y-auto bg-white h-full z-50',
		$b2bSessionDrawer.isExpanded
			? ''
			: 'md:translate-x-full translate-y-full md:translate-y-0 opacity-0'
	)}
>
	<div class="bg-accent-primary">
		<div class="p-4">
			<div class="flex justify-between">
				<button
					on:click={() => b2bSessionDrawer.setSessionType('default')}
					class={classes(
						'i-sprenger-chevron-left w-6 h-6 bg-white transition duration-500',
						$b2bSessionDrawer.sessionType !== 'default'
							? 'opacity-100'
							: 'opacity-0 pointer-events-none'
					)}
				></button>
				<button
					on:click={() => b2bSessionDrawer.setIsExpanded(false)}
					class="i-sprenger-x w-6 h-6 bg-white"
				></button>
			</div>
			<B2BSessionDrawerHeader />
		</div>
	</div>
</div>
