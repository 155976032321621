<script>
	import { classes } from '@thearc-hq/ui-kit/utils';

	export let color = '';
	export let isLeft = false;
</script>

<div
	style="background-color: {color};"
	class={classes(
		'absolute',
		'bottom-0',
		'h-4',
		'w-[65%]',
		'lg:h-[23px]',
		'z-10',
		isLeft ? 'left-0' : 'right-0'
	)}
/>
