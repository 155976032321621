import { readonly, writable } from 'svelte/store';

const createNavBarSizeStore = () => {
	// 64px is the default size of the nav bar
	const data = writable(64 + 42);

	return {
		...readonly(data),
		updateSize: data.set
	};
};

export const navBarSize = createNavBarSizeStore();
