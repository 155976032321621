<script lang="ts">
	import { getTranslate, T } from '@tolgee/svelte';
	import { field, form } from 'svelte-forms';
	import { createSubscription } from '$lib/klaviyo';
	import {
		NOTIFICATION,
		NOTIFICATION_DURATION,
		notificationStore
	} from '$lib/store/notification.store';
	import FormFieldCheckbox from '$lib/components/Form/FormFieldCheckbox.svelte';
	import FormFieldTextInput from '$lib/components/Form/FormFieldTextInput.svelte';
	import SocialMediaList from '$lib/components/Footer/SocialMediaList.svelte';

	const { t } = getTranslate();

	const showVerifyEmail = () =>
		notificationStore.addNotification({
			hideAfter: NOTIFICATION_DURATION.LONG,
			text: $t('modals.newsletterSettings.confirm.content'),
			title: $t('modals.newsletterSettings.confirm.title'),
			variant: NOTIFICATION.ALERT
		});

	const [equineCheckbox, dogCheckbox, boatCheckbox, email] = [
		field('listEquine', true),
		field('listDog', true),
		field('listBoat', true),
		field('email', '')
	];
	const newsletterForm = form(equineCheckbox, dogCheckbox, boatCheckbox, email);
	$: errors = $newsletterForm.errors;

	const handleSubmit = async () => {
		const isEverythingSelected =
			!$dogCheckbox.value && !$boatCheckbox.value && !$equineCheckbox.value;
		await createSubscription($email.value, 'newsletter', {
			listDog: isEverythingSelected || $dogCheckbox.value,
			listBoat: isEverythingSelected || $boatCheckbox.value,
			listEquine: isEverythingSelected || $equineCheckbox.value
		});
		showVerifyEmail();
	};
</script>

<div class="grid md:grid-cols-3 mt-1 md:mt-0 gap-y-5 lg:-mb-4 relative lg:min-w-md min-w-sm">
	<label for="inputNewsletterEmail" class="flex flex-col w-full md:flex-row">
		<span class="text-h-16 mr-6 self-auto flex-none md:self-center">
			<T keyName="newsletter.subscribe" />
		</span>
	</label>
	<form class="w-72 -mt-6 md:mt-0 pr-2 col-span-2 md:grow" on:submit|preventDefault={handleSubmit}>
		<div class="space-y-2 mb-5">
			<div class="text-p-12">{$t('newsletter.list.title')}</div>
			<div class="flex gap-8">
				<FormFieldCheckbox bind:checked={$equineCheckbox.value} {errors} name="listEquine">
					<span class="text-white">{$t('newsletter.list.equine')}</span>
				</FormFieldCheckbox>
				<FormFieldCheckbox bind:checked={$dogCheckbox.value} {errors} name="listDog">
					<span class="text-white">{$t('newsletter.list.dog')}</span>
				</FormFieldCheckbox>
				<FormFieldCheckbox bind:checked={$boatCheckbox.value} {errors} name="listBoat">
					<span class="text-white">{$t('newsletter.list.boat')}</span>
				</FormFieldCheckbox>
			</div>
		</div>
		<div id="inputNewsletterEmail" class="relative">
			<FormFieldTextInput
				class="mt-6 md:mt-0"
				label={$t('customer.email')}
				type="email"
				name="newsLetterEmail"
				showChevron={true}
				bind:value={$email.value}
				{errors}
				required
			/>
		</div>
	</form>
	<div class="text-h-16 mt-6">
		<span>
			<T keyName="newsletter.followUs" />
		</span>
	</div>
	<div class="flex md:flex-row flex-wrap flex-col text-h-16 gap-5 col-span-2">
		<SocialMediaList
			facebookLink="https://www.facebook.com/sprengerdogsports"
			instagramLink="https://www.instagram.com/sprenger_dogsports/?hl=de"
			youtubeLink="https://www.youtube.com/channel/UCxqqFUAtie51LIJSojw1yRg"
			title="Dogsports"
			class="text-special-dog"
		/>
		<SocialMediaList
			instagramLink="https://www.instagram.com/sprenger_equestrian/?hl=de"
			youtubeLink="https://www.youtube.com/channel/UCxqqFUAtie51LIJSojw1yRg"
			title="Equestrian"
			class="text-special-horse"
		/>
		<SocialMediaList
			instagramLink="https://www.instagram.com/sprenger_boatsports/?hl=de"
			youtubeLink="https://www.youtube.com/channel/UCxqqFUAtie51LIJSojw1yRg"
			title="Boatsports"
			class="text-special-boat"
		/>
	</div>
</div>
