<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';
	import type { ImageStoryblok } from '$lib/storyblok/types';
	import StoryblokImageOrVideo from '$lib/components/Image/StoryblokImageOrVideo.svelte';

	export let className: string;
	export let objectFit: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down' = 'contain';
	export let image_1: ImageStoryblok;
	export let image_2: ImageStoryblok;
	export let imageProps = {};

	export { className as class };

	const _OBJECT_LOOKUP = {
		contain: 'object-contain',
		cover: 'object-cover',
		fill: 'object-fill',
		none: 'object-none',
		'scale-down': 'object-scale-down'
	};

	$: objectClassName = classes(
		'transition-opacity duration-500 ease-in-out absolute w-full h-full',
		_OBJECT_LOOKUP[objectFit]
	);
</script>

<div class={classes('relative group', className)}>
	{#if image_1?.filename}
		<StoryblokImageOrVideo
			{...imageProps}
			class={classes(objectClassName, 'group-hover:opacity-0 opacity-100')}
			width={2560}
			height={1440}
			src={image_1.filename}
			placeholder="blur"
			sizes="75vw"
			focus={image_1.focus}
			alt={image_1.alt}
			layout="responsive"
			title={image_1.title}
			{objectFit}
		/>
	{/if}
	<!--TODO: add blurDataURL={withBlur(image_1.filename)} after we got withBlur()-->

	{#if image_2?.filename}
		<StoryblokImageOrVideo
			{...imageProps}
			class={classes(objectClassName, 'group-hover:opacity-100 opacity-0')}
			width={2560}
			height={1440}
			src={image_2.filename}
			placeholder="blur"
			sizes="75vw"
			focus={image_2.focus}
			alt={image_2.alt}
			title={image_2.title}
			{objectFit}
		/>
	{/if}
	<!--TODO: add blurDataURL={withBlur(image_2.filename)} after we got withBlur()-->
</div>
