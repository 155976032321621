import Placeholder from '$lib/components/Storyblok/Placeholder.svelte';
import MaterialAndCareBase from '$lib/components/Storyblok/MaterialAndCareBase.svelte';
import AccordionBlock from '$lib/components/Storyblok/AccordionBlock.svelte';
import ContentBlock1 from '$lib/components/Storyblok/ContentBlock1.svelte';
import ContentBlock10 from '$lib/components/Storyblok/ContentBlock10.svelte';
import ContentBlock11 from '$lib/components/Storyblok/ContentBlock11.svelte';
import ContentBlock12 from '$lib/components/Storyblok/ContentBlock12.svelte';
import ContentBlock13 from '$lib/components/Storyblok/ContentBlock13.svelte';
import ContentBlock2 from '$lib/components/Storyblok/ContentBlock2.svelte';
import ContentBlock3 from '$lib/components/Storyblok/ContentBlock3.svelte';
import ContentBlock4 from '$lib/components/Storyblok/ContentBlock4.svelte';
import ContentBlock5 from '$lib/components/Storyblok/ContentBlock5.svelte';
import ContentBlock7 from '$lib/components/Storyblok/ContentBlock7.svelte';
import ContentBlock8 from '$lib/components/Storyblok/ContentBlock8.svelte';
import ContentBlock9 from '$lib/components/Storyblok/ContentBlock9.svelte';
import Hero from '$lib/components/Storyblok/Hero.svelte';
import HeroSlider from '$lib/components/Storyblok/HeroSlider.svelte';
import RichTextBlock from '$lib/components/Storyblok/RichTextBlock.svelte';
import Section from '$lib/components/Storyblok/Section.svelte';
import Slider from '$lib/components/Storyblok/Slider.svelte';
import YouTubeBlock from '$lib/components/Storyblok/YouTubeBlock.svelte';
import MagazineTitle from '$lib/components/Storyblok/MagazinTitle.svelte';
import FAQSection from '$lib/components/FAQ/FAQSection.svelte';
import NewsletterBlock from '$lib/components/Storyblok/NewsletterBlock.svelte';
import MagazinShare from '$lib/components/Storyblok/MagazinShare.svelte';
import ContentBlock6 from '$lib/components/Storyblok/ContentBlock6.svelte';
import ContentBlock14 from '$lib/components/Storyblok/ContentBlock14.svelte';
import QAndABase from '$lib/components/Storyblok/QAndABase.svelte';
import HistoryBlock from '$lib/components/Storyblok/HistoryBlock.svelte';
import Headline from '$lib/components/Storyblok/Headline.svelte';

export const StoryblokComponents = {
	box: Placeholder,
	care: MaterialAndCareBase,
	accordionBlock: AccordionBlock,
	contentBlock1: ContentBlock1,
	contentBlock10: ContentBlock10,
	contentBlock11: ContentBlock11,
	contentBlock12: ContentBlock12,
	contentBlock13: ContentBlock13,
	contentBlock14: ContentBlock14,
	contentBlock2: ContentBlock2,
	contentBlock3: ContentBlock3,
	contentBlock4: ContentBlock4,
	contentBlock5: ContentBlock5,
	contentBlock6: ContentBlock6,
	contentBlock7: ContentBlock7,
	contentBlock8: ContentBlock8,
	contentBlock9: ContentBlock9,
	faqItem: Placeholder,
	faqList: Placeholder,
	headline: Headline,
	hero: Hero,
	hero2: Placeholder,
	heroSlider: HeroSlider,
	historyBlock: HistoryBlock,
	material: MaterialAndCareBase,
	questionAnswer: QAndABase,
	richTextBlock: RichTextBlock,
	section: Section,
	slider: Slider,
	youtubeBlock: YouTubeBlock,
	magazinTitle: MagazineTitle,
	magazinShare: MagazinShare,
	faqSection: FAQSection,
	shopTheLook: Placeholder,
	newsletterBlock: NewsletterBlock,
	footer: Placeholder
};
