<script>
	import { T } from '@tolgee/svelte';
	import { resolveRoute } from '$app/paths';
	import { page } from '$app/stores';
</script>

<div
	class="py-4 flex flex-col items-start md:items-center gap-x-9 gap-y-4 font-semibold uppercase md:flex-row md:flex-wrap text-h-12"
>
	<a
		href={resolveRoute('/[locale]-[country]/imprint', {
			...$page.params
		})}
	>
		<T keyName="footer.imprint" />
	</a>

	<a
		href={resolveRoute('/[locale]-[country]/privacy', {
			...$page.params
		})}
	>
		<T keyName="footer.privacyPolicy" />
	</a>

	<a
		href={resolveRoute('/[locale]-[country]/agb', {
			...$page.params
		})}
	>
		<T keyName="footer.abg" />
	</a>

	<a
		href={resolveRoute('/[locale]-[country]/widerrufsrecht', {
			...$page.params
		})}
	>
		<T keyName="footer.widerrufsrecht" />
	</a>

	<a
		href={resolveRoute('/[locale]-[country]/zahlung-versand', {
			...$page.params
		})}
	>
		<T keyName="footer.zahlungVersand" />
	</a>

	<a href="https://shop-sprenger.returnsportal.online/">
		<T keyName="footer.refundPortal" />
	</a>

	<a
		href={resolveRoute('/[locale]-[country]/about-us', {
			...$page.params
		})}
	>
		<T keyName="footer.aboutUs" />
	</a>

	<a href="https://sprenger.de" target="_blank">
		<T keyName="links.b2b" />
	</a>
</div>
