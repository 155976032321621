<script>
	import RichText from '$lib/components/Storyblok/RichText.svelte';
	import StoryblokButton from '$lib/components/Button/StoryblokButton.svelte';
	import { classes } from '@thearc-hq/ui-kit/utils';

	export let subtitle;
	export let title;
	export let description;
	let className;
	export let button;

	export { className as class };
</script>

<div class={classes('space-y-4 lg:w-11/12', className)}>
	<div class="flex flex-col">
		<span class="text-h-16 mt-6">{subtitle}</span>
		{#if title}
			<span class="text-h-32 hyphens-auto">{title}</span>
		{/if}
	</div>
	<RichText text={description} />
	<div class="flex flex-col w-full items-stretch">
		{#if button}
			<StoryblokButton {...button} />
		{/if}
	</div>
</div>
