import type { CollectionTree } from './collectionTree';

export const faqNavMenuList: CollectionTree = {
	items: [
		{
			link: '/[locale]-[country]/faq',
			title: 'nav.faq.general'
		},
		{
			link: '/[locale]-[country]/faq/equine',
			title: 'nav.faq.horse'
		},
		{
			link: '/[locale]-[country]/faq/dog',
			title: 'nav.faq.dog'
		},
		{
			link: '/[locale]-[country]/faq/boat',
			title: 'nav.faq.boat'
		}
	],
	link: '/[locale]-[country]/faq',
	title: 'nav.faq.title'
};

type HelpLink = {
	link: string;
	title: string;
	isExternal?: boolean;
};

export const menuHelpLinkList: HelpLink[] = [
	{
		link: '/[locale]-[country]/magazin',
		title: 'links.magazine'
	},
	{
		link: '/[locale]-[country]/zahlung-versand',
		title: 'links.paymentAndDelivery'
	}
];

export const menuCustomerLinkList: HelpLink[] = [
	{
		link: '/[locale]-[country]/customer/orders',
		title: 'links.myOrders'
	},
	{
		link: '/[locale]-[country]/customer/profile',
		title: 'links.myProfile'
	},
	{
		link: 'https://sprenger.de',
		title: 'links.b2b',
		isExternal: true
	}
];

export const aboutNavMenuList = {
	items: [
		{
			link: '/[locale]-[country]/about-us/company-history',
			title: 'nav.aboutUs.companyHistory'
		},
		{
			link: '/[locale]-[country]/about-us/career',
			title: 'nav.aboutUs.career.title',
			items: [
				{
					link: '/[locale]-[country]/about-us/career',
					title: 'nav.aboutUs.career.jobs'
				},
				{
					link: '/[locale]-[country]/about-us/career/apprenticeship',
					title: 'nav.aboutUs.career.apprenticeship'
				},
				{
					link: '/[locale]-[country]/about-us/career/internship',
					title: 'nav.aboutUs.career.internship'
				}
			]
		},
		{
			link: '/[locale]-[country]/news-press',
			title: 'nav.aboutUs.newsPress'
		},
		{
			link: '/[locale]-[country]/champions',
			title: 'nav.aboutUs.champions'
		}
	],
	link: '/[locale]-[country]/about-us',
	title: 'nav.aboutUs.title'
};
