<script lang="ts">
	import { storyblokEditable } from '@storyblok/svelte';
	import type { ContentBlock5Props } from '$lib/storyblok/types';
	import ImageComponent from '$lib/components/Storyblok/ContentBlock5/ImageComponent.svelte';
	import FeaturedLayoutComponent from '$lib/components/Storyblok/ContentBlock5/FeaturedLayoutComponent.svelte';
	import InformationComponent from '$lib/components/Storyblok/ContentBlock5/InformationComponent.svelte';
	import { getImagePriorityBelowFold } from '$lib/utils/image';

	export let blok: ContentBlock5Props;

	export let position: number = 0;
	$: isBelowFold = position > 0;
	$: button = blok.button_text
		? { text: blok.button_text, url: blok.button_link.url, className: 'w-full mt-6' }
		: undefined;
</script>

<div use:storyblokEditable={blok} class:bg-accent-primary_005={blok.blue_background}>
	<div class="md:space-y-0 box-x-wrapper w-full py-11 md:py-16">
		<div class="flex flex-row w-full md:space-x-3">
			{#if blok.image_emphasis === 'right'}
				{#if blok.image_right}
					<ImageComponent
						class="w-full lg:w-2/3"
						{...blok.image_right}
						parallax={blok.image_right_parallax}
						shopTheLook={blok.shopTheLook1}
						video_id={blok.video_id}
						autoplay={blok.autoplay}
						{...getImagePriorityBelowFold(isBelowFold)}
					/>
				{/if}
				<FeaturedLayoutComponent>
					{#if blok.image_left}
						<ImageComponent
							{...blok.image_left}
							parallax={blok.image_left_parallax}
							shopTheLook={blok.shopTheLook2}
							class="hidden md:flex"
							{...getImagePriorityBelowFold(isBelowFold)}
						/>
					{/if}
					<InformationComponent
						title={blok.title}
						subtitle={blok.subtitle}
						description={blok.description}
						class="hidden lg:block"
						{button}
					/>
				</FeaturedLayoutComponent>
			{:else}
				<FeaturedLayoutComponent>
					{#if blok.image_left}
						<ImageComponent
							{...blok.image_left}
							parallax={blok.image_left_parallax}
							shopTheLook={blok.shopTheLook2}
							class="hidden md:flex"
							{...getImagePriorityBelowFold(isBelowFold)}
						/>
					{/if}
					<InformationComponent
						title={blok.title}
						subtitle={blok.subtitle}
						description={blok.description}
						class="hidden lg:block"
						{button}
					/>
				</FeaturedLayoutComponent>
				{#if blok.image_right}
					<ImageComponent
						class="w-full lg:w-2/3"
						{...blok.image_right}
						parallax={blok.image_right_parallax}
						shopTheLook={blok.shopTheLook1}
						video_id={blok.video_id}
						autoplay={blok.autoplay}
						{...getImagePriorityBelowFold(isBelowFold)}
					/>
				{/if}
			{/if}
		</div>
		<InformationComponent
			title={blok.title}
			subtitle={blok.subtitle}
			description={blok.description}
			class="lg:hidden"
		/>
	</div>
</div>
