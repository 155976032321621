<script>
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { getTranslate } from '@tolgee/svelte';

	export let item;
	export let index;
	export let depth;

	const { t } = getTranslate();

	$: hasSubItems = (item.items?.length ?? 0) > 0;
</script>

<div class="flex justify-between md:max-w-xs">
	<div class={classes(depth > 0 && index > 0 ? 'text-h-16' : 'text-h-24')}>
		{item.title.includes('nav.') ? $t(item.title) : item.title}
	</div>

	{#if hasSubItems}
		<div class="i-sprenger-chevron-right w-6 h-6"></div>
	{/if}
</div>
