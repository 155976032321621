<script>
	import { classes } from '@thearc-hq/ui-kit/utils';
	import StoryblokImageOrVideo from '$lib/components/Image/StoryblokImageOrVideo.svelte';

	export let filename;
	export let alt;
	let className;
	export let video_id;
	export let autoplay;
	export let shopTheLook;
	export let focus;
	export let parallax;
	export let title;

	export { className as class };
</script>

{#if !!(filename || video_id || shopTheLook)}
	<div class={classes('relative aspect-[1/1] md:aspect-[9/10]', className)}>
		<StoryblokImageOrVideo
			{filename}
			{title}
			placeholder="blur"
			{parallax}
			{alt}
			{focus}
			sizes="50vw"
			class="object-cover h-full w-full aspect-[1/1] md:aspect-[9/10] absolute"
			{shopTheLook}
			{video_id}
			{autoplay}
			{...$$restProps}
		/>
	</div>
{/if}
