<script lang="ts">
	import type { ContentBlock12Props } from '$lib/storyblok/types';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { storyblokEditable } from '@storyblok/svelte';
	import RichText from '$lib/components/Storyblok/RichText.svelte';
	import StoryblokButton from '$lib/components/Button/StoryblokButton.svelte';
	import ImageHover from '$lib/components/Image/ImageHover.svelte';
	import StoryblokImageOrVideo from '$lib/components/Image/StoryblokImageOrVideo.svelte';
	import { getImagePriorityBelowFold } from '$lib/utils/image';

	export let blok: ContentBlock12Props;
	export let position: number = 0;
	$: isBelowFold = position > 0;
	$: button = blok.button_text
		? { text: blok.button_text, url: blok.button_link!.url, class: 'w-full my-6' }
		: undefined;
</script>

<div use:storyblokEditable={blok} class={classes(blok.blue_background && 'bg-accent-primary_005')}>
	<div class="box-x-wrapper flex justify-center py-11 md:py-16">
		<div
			class={classes(
				'flex content-center h-full relative',
				blok.size === 'small' ? 'lg:w-2/3' : 'w-full',
				blok.text_emphasis === 'top' ? 'flex-col' : 'flex-col-reverse gap-4'
			)}
		>
			<div class="center-block">
				{#if blok.title}
					<div class="text-h-32 pb-4 hyphens-auto">{blok.title}</div>
				{/if}
				<RichText class="text-p-16 pb-4" text={blok.description} />
				<div class="flex flex-col md:items-start relative z-10">
					{#if button}
						<StoryblokButton {...button} />
					{/if}
				</div>
			</div>
			{#if blok.image_1?.filename && blok.image_2?.filename && !blok.video_id}
				<ImageHover
					class="pt-4 aspect-5/4 lg:aspect-video lg:max-h-160 overflow-hidden"
					image_1={blok.image_1}
					image_2={blok.image_2}
					objectFit="cover"
					imageProps={getImagePriorityBelowFold(isBelowFold)}
				/>
			{/if}
			{#if blok.image_1?.filename && !blok.image_2?.filename && (blok.video_id || blok.shopTheLook)}
				<StoryblokImageOrVideo
					filename={blok.image_1?.filename}
					alt={blok.image_1?.alt ?? undefined}
					focus={blok.image_1?.focus}
					layout="responsive"
					width={2560}
					height={1440}
					sizes="75vw"
					objectFit="cover"
					video_id={blok.video_id}
					autoplay={blok.autoplay}
					shopTheLook={blok.shopTheLook}
					class="aspect-video object-cover w-full"
					title={blok.image_1?.title}
					{...getImagePriorityBelowFold(isBelowFold)}
				/>
			{/if}
		</div>
	</div>
</div>
