<script>
	import { isProd } from '$lib/utils';
	import { onMount } from 'svelte';

	let dismissed = isProd();

	onMount(() => {
		if (localStorage.getItem('stgBannerDismissed')) {
			dismissed = true;
		}
	});
</script>

{#if !dismissed}
	<div
		class="relative w-full h-16 md:h-10 text-white justify-center items-center flex flex-col md:flex-row bg-special-danger px-2 text-h-16"
	>
		Sie befinden sich auf einer Testumgebung.<a
			class="ml-2 underline"
			href="https://www.sprenger.de">Hier geht es zum Shop</a
		>
		<button
			on:click={() => {
				localStorage.setItem('stgBannerDismissed', 'true');
				dismissed = true;
			}}
			class="absolute top-0 right-4 flex items-center h-full i-sprenger-x"
		></button>
	</div>
{/if}
