export default {
    "name": "TwoProductsQuery",
    "kind": "HoudiniQuery",
    "hash": "4a8ba51417abd698c249be918bac1d3b4b367cd96c4d683b84be4a624925f195",

    "raw": `query TwoProductsQuery($firstHandle: String, $secondHandle: String) {
  firstProduct: product(handle: $firstHandle) {
    ...ProductCardFragment
    id
  }
  secondProduct: product(handle: $secondHandle) {
    ...ProductCardFragment
    id
  }
}

fragment ProductCardFragment on Product {
  id
  title
  tags
  totalInventory
  handle
  description
  descriptionHtml
  availableForSale
  is_single: metafield(key: "is_single", namespace: "sprenger") {
    key
    value
    id
  }
  priceRange {
    maxVariantPrice {
      amount
      currencyCode
    }
    minVariantPrice {
      amount
      currencyCode
    }
  }
  compareAtPriceRange {
    maxVariantPrice {
      amount
      currencyCode
    }
    minVariantPrice {
      amount
      currencyCode
    }
  }
  options(first: 10) {
    id
    name
    values
  }
  dreid: metafield(key: "dreid", namespace: "sprenger") {
    key
    value
    id
  }
  video: metafield(key: "video", namespace: "sprenger") {
    key
    value
    id
  }
  measure_d2c: metafield(key: "measure_d2c", namespace: "sprenger") {
    key
    value
    id
  }
  upSellSkus: metafield(key: "upsell_skus", namespace: "sprenger") {
    key
    value
    id
  }
  sku: metafield(key: "just_sku", namespace: "sprenger") {
    key
    value
    id
  }
  is_single: metafield(key: "is_single", namespace: "sprenger") {
    key
    value
    id
  }
  colormap: metafield(key: "colormap", namespace: "sprenger") {
    key
    value
    id
  }
  highlights: metafield(key: "highlights", namespace: "sprenger") {
    key
    value
    id
  }
  imageUrls: metafield(key: "image_urls", namespace: "sprenger") {
    key
    value
    id
  }
  bulletPoints: metafield(key: "bullets", namespace: "sprenger") {
    key
    value
    id
  }
  measurement_table: metafield(key: "measurement_table", namespace: "sprenger") {
    key
    value
    id
  }
  placeholderImages: images(first: 10) {
    edges {
      node {
        src
        id
      }
    }
  }
  images(first: 10) {
    edges {
      node {
        src
        altText
        id
      }
    }
  }
  collections(first: 100) {
    edges {
      node {
        title
        handle
        description
        path: metafield(key: "path", namespace: "sprenger") {
          key
          value
          id
        }
        id
      }
    }
  }
  variants(first: 100) {
    edges {
      node {
        id
        selectedOptions {
          name
          value
        }
        price {
          amount
          currencyCode
        }
        compareAtPrice {
          amount
          currencyCode
        }
        imageUrls: metafield(key: "image_urls", namespace: "sprenger") {
          key
          value
          id
        }
        sku: metafield(key: "just_sku", namespace: "sprenger") {
          key
          value
          id
        }
        quantityAvailable
      }
    }
  }
  __typename
}
`,

    "rootType": "QueryRoot",

    "selection": {
        "fields": {
            "firstProduct": {
                "type": "Product",
                "keyRaw": "firstProduct(handle: $firstHandle)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "ID",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "title": {
                            "type": "String",
                            "keyRaw": "title",
                            "visible": true
                        },

                        "tags": {
                            "type": "String",
                            "keyRaw": "tags",
                            "visible": true
                        },

                        "totalInventory": {
                            "type": "Int",
                            "keyRaw": "totalInventory",
                            "nullable": true,
                            "visible": true
                        },

                        "handle": {
                            "type": "String",
                            "keyRaw": "handle",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        },

                        "descriptionHtml": {
                            "type": "HTML",
                            "keyRaw": "descriptionHtml",
                            "visible": true
                        },

                        "availableForSale": {
                            "type": "Boolean",
                            "keyRaw": "availableForSale",
                            "visible": true
                        },

                        "is_single": {
                            "type": "Metafield",
                            "keyRaw": "is_single(key: \"is_single\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "priceRange": {
                            "type": "ProductPriceRange",
                            "keyRaw": "priceRange",

                            "selection": {
                                "fields": {
                                    "maxVariantPrice": {
                                        "type": "MoneyV2",
                                        "keyRaw": "maxVariantPrice",

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "minVariantPrice": {
                                        "type": "MoneyV2",
                                        "keyRaw": "minVariantPrice",

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "compareAtPriceRange": {
                            "type": "ProductPriceRange",
                            "keyRaw": "compareAtPriceRange",

                            "selection": {
                                "fields": {
                                    "maxVariantPrice": {
                                        "type": "MoneyV2",
                                        "keyRaw": "maxVariantPrice",

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "minVariantPrice": {
                                        "type": "MoneyV2",
                                        "keyRaw": "minVariantPrice",

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "options": {
                            "type": "ProductOption",
                            "keyRaw": "options(first: 10)",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "values": {
                                        "type": "String",
                                        "keyRaw": "values",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "dreid": {
                            "type": "Metafield",
                            "keyRaw": "dreid(key: \"dreid\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "video": {
                            "type": "Metafield",
                            "keyRaw": "video(key: \"video\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "measure_d2c": {
                            "type": "Metafield",
                            "keyRaw": "measure_d2c(key: \"measure_d2c\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "upSellSkus": {
                            "type": "Metafield",
                            "keyRaw": "upSellSkus(key: \"upsell_skus\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "sku": {
                            "type": "Metafield",
                            "keyRaw": "sku(key: \"just_sku\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "colormap": {
                            "type": "Metafield",
                            "keyRaw": "colormap(key: \"colormap\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "highlights": {
                            "type": "Metafield",
                            "keyRaw": "highlights(key: \"highlights\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "imageUrls": {
                            "type": "Metafield",
                            "keyRaw": "imageUrls(key: \"image_urls\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "bulletPoints": {
                            "type": "Metafield",
                            "keyRaw": "bulletPoints(key: \"bullets\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "measurement_table": {
                            "type": "Metafield",
                            "keyRaw": "measurement_table(key: \"measurement_table\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "placeholderImages": {
                            "type": "ImageConnection",
                            "keyRaw": "placeholderImages(first: 10)",

                            "selection": {
                                "fields": {
                                    "edges": {
                                        "type": "ImageEdge",
                                        "keyRaw": "edges",

                                        "selection": {
                                            "fields": {
                                                "node": {
                                                    "type": "Image",
                                                    "keyRaw": "node",

                                                    "selection": {
                                                        "fields": {
                                                            "src": {
                                                                "type": "URL",
                                                                "keyRaw": "src",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true,
                                                                "nullable": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "images": {
                            "type": "ImageConnection",
                            "keyRaw": "images(first: 10)",

                            "selection": {
                                "fields": {
                                    "edges": {
                                        "type": "ImageEdge",
                                        "keyRaw": "edges",

                                        "selection": {
                                            "fields": {
                                                "node": {
                                                    "type": "Image",
                                                    "keyRaw": "node",

                                                    "selection": {
                                                        "fields": {
                                                            "src": {
                                                                "type": "URL",
                                                                "keyRaw": "src",
                                                                "visible": true
                                                            },

                                                            "altText": {
                                                                "type": "String",
                                                                "keyRaw": "altText",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true,
                                                                "nullable": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "collections": {
                            "type": "CollectionConnection",
                            "keyRaw": "collections(first: 100)",

                            "selection": {
                                "fields": {
                                    "edges": {
                                        "type": "CollectionEdge",
                                        "keyRaw": "edges",

                                        "selection": {
                                            "fields": {
                                                "node": {
                                                    "type": "Collection",
                                                    "keyRaw": "node",

                                                    "selection": {
                                                        "fields": {
                                                            "title": {
                                                                "type": "String",
                                                                "keyRaw": "title",
                                                                "visible": true
                                                            },

                                                            "handle": {
                                                                "type": "String",
                                                                "keyRaw": "handle",
                                                                "visible": true
                                                            },

                                                            "description": {
                                                                "type": "String",
                                                                "keyRaw": "description",
                                                                "visible": true
                                                            },

                                                            "path": {
                                                                "type": "Metafield",
                                                                "keyRaw": "path(key: \"path\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "variants": {
                            "type": "ProductVariantConnection",
                            "keyRaw": "variants(first: 100)",

                            "selection": {
                                "fields": {
                                    "edges": {
                                        "type": "ProductVariantEdge",
                                        "keyRaw": "edges",

                                        "selection": {
                                            "fields": {
                                                "node": {
                                                    "type": "ProductVariant",
                                                    "keyRaw": "node",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "selectedOptions": {
                                                                "type": "SelectedOption",
                                                                "keyRaw": "selectedOptions",

                                                                "selection": {
                                                                    "fields": {
                                                                        "name": {
                                                                            "type": "String",
                                                                            "keyRaw": "name",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "price": {
                                                                "type": "MoneyV2",
                                                                "keyRaw": "price",

                                                                "selection": {
                                                                    "fields": {
                                                                        "amount": {
                                                                            "type": "Decimal",
                                                                            "keyRaw": "amount",
                                                                            "visible": true
                                                                        },

                                                                        "currencyCode": {
                                                                            "type": "CurrencyCode",
                                                                            "keyRaw": "currencyCode",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "compareAtPrice": {
                                                                "type": "MoneyV2",
                                                                "keyRaw": "compareAtPrice",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "amount": {
                                                                            "type": "Decimal",
                                                                            "keyRaw": "amount",
                                                                            "visible": true
                                                                        },

                                                                        "currencyCode": {
                                                                            "type": "CurrencyCode",
                                                                            "keyRaw": "currencyCode",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "imageUrls": {
                                                                "type": "Metafield",
                                                                "keyRaw": "imageUrls(key: \"image_urls\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "sku": {
                                                                "type": "Metafield",
                                                                "keyRaw": "sku(key: \"just_sku\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "quantityAvailable": {
                                                                "type": "Int",
                                                                "keyRaw": "quantityAvailable",
                                                                "nullable": true,
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "ProductCardFragment": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            },

            "secondProduct": {
                "type": "Product",
                "keyRaw": "secondProduct(handle: $secondHandle)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "ID",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "title": {
                            "type": "String",
                            "keyRaw": "title",
                            "visible": true
                        },

                        "tags": {
                            "type": "String",
                            "keyRaw": "tags",
                            "visible": true
                        },

                        "totalInventory": {
                            "type": "Int",
                            "keyRaw": "totalInventory",
                            "nullable": true,
                            "visible": true
                        },

                        "handle": {
                            "type": "String",
                            "keyRaw": "handle",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        },

                        "descriptionHtml": {
                            "type": "HTML",
                            "keyRaw": "descriptionHtml",
                            "visible": true
                        },

                        "availableForSale": {
                            "type": "Boolean",
                            "keyRaw": "availableForSale",
                            "visible": true
                        },

                        "is_single": {
                            "type": "Metafield",
                            "keyRaw": "is_single(key: \"is_single\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "priceRange": {
                            "type": "ProductPriceRange",
                            "keyRaw": "priceRange",

                            "selection": {
                                "fields": {
                                    "maxVariantPrice": {
                                        "type": "MoneyV2",
                                        "keyRaw": "maxVariantPrice",

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "minVariantPrice": {
                                        "type": "MoneyV2",
                                        "keyRaw": "minVariantPrice",

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "compareAtPriceRange": {
                            "type": "ProductPriceRange",
                            "keyRaw": "compareAtPriceRange",

                            "selection": {
                                "fields": {
                                    "maxVariantPrice": {
                                        "type": "MoneyV2",
                                        "keyRaw": "maxVariantPrice",

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "minVariantPrice": {
                                        "type": "MoneyV2",
                                        "keyRaw": "minVariantPrice",

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "options": {
                            "type": "ProductOption",
                            "keyRaw": "options(first: 10)",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "values": {
                                        "type": "String",
                                        "keyRaw": "values",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "dreid": {
                            "type": "Metafield",
                            "keyRaw": "dreid(key: \"dreid\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "video": {
                            "type": "Metafield",
                            "keyRaw": "video(key: \"video\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "measure_d2c": {
                            "type": "Metafield",
                            "keyRaw": "measure_d2c(key: \"measure_d2c\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "upSellSkus": {
                            "type": "Metafield",
                            "keyRaw": "upSellSkus(key: \"upsell_skus\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "sku": {
                            "type": "Metafield",
                            "keyRaw": "sku(key: \"just_sku\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "colormap": {
                            "type": "Metafield",
                            "keyRaw": "colormap(key: \"colormap\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "highlights": {
                            "type": "Metafield",
                            "keyRaw": "highlights(key: \"highlights\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "imageUrls": {
                            "type": "Metafield",
                            "keyRaw": "imageUrls(key: \"image_urls\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "bulletPoints": {
                            "type": "Metafield",
                            "keyRaw": "bulletPoints(key: \"bullets\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "measurement_table": {
                            "type": "Metafield",
                            "keyRaw": "measurement_table(key: \"measurement_table\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "placeholderImages": {
                            "type": "ImageConnection",
                            "keyRaw": "placeholderImages(first: 10)",

                            "selection": {
                                "fields": {
                                    "edges": {
                                        "type": "ImageEdge",
                                        "keyRaw": "edges",

                                        "selection": {
                                            "fields": {
                                                "node": {
                                                    "type": "Image",
                                                    "keyRaw": "node",

                                                    "selection": {
                                                        "fields": {
                                                            "src": {
                                                                "type": "URL",
                                                                "keyRaw": "src",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true,
                                                                "nullable": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "images": {
                            "type": "ImageConnection",
                            "keyRaw": "images(first: 10)",

                            "selection": {
                                "fields": {
                                    "edges": {
                                        "type": "ImageEdge",
                                        "keyRaw": "edges",

                                        "selection": {
                                            "fields": {
                                                "node": {
                                                    "type": "Image",
                                                    "keyRaw": "node",

                                                    "selection": {
                                                        "fields": {
                                                            "src": {
                                                                "type": "URL",
                                                                "keyRaw": "src",
                                                                "visible": true
                                                            },

                                                            "altText": {
                                                                "type": "String",
                                                                "keyRaw": "altText",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true,
                                                                "nullable": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "collections": {
                            "type": "CollectionConnection",
                            "keyRaw": "collections(first: 100)",

                            "selection": {
                                "fields": {
                                    "edges": {
                                        "type": "CollectionEdge",
                                        "keyRaw": "edges",

                                        "selection": {
                                            "fields": {
                                                "node": {
                                                    "type": "Collection",
                                                    "keyRaw": "node",

                                                    "selection": {
                                                        "fields": {
                                                            "title": {
                                                                "type": "String",
                                                                "keyRaw": "title",
                                                                "visible": true
                                                            },

                                                            "handle": {
                                                                "type": "String",
                                                                "keyRaw": "handle",
                                                                "visible": true
                                                            },

                                                            "description": {
                                                                "type": "String",
                                                                "keyRaw": "description",
                                                                "visible": true
                                                            },

                                                            "path": {
                                                                "type": "Metafield",
                                                                "keyRaw": "path(key: \"path\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "variants": {
                            "type": "ProductVariantConnection",
                            "keyRaw": "variants(first: 100)",

                            "selection": {
                                "fields": {
                                    "edges": {
                                        "type": "ProductVariantEdge",
                                        "keyRaw": "edges",

                                        "selection": {
                                            "fields": {
                                                "node": {
                                                    "type": "ProductVariant",
                                                    "keyRaw": "node",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "selectedOptions": {
                                                                "type": "SelectedOption",
                                                                "keyRaw": "selectedOptions",

                                                                "selection": {
                                                                    "fields": {
                                                                        "name": {
                                                                            "type": "String",
                                                                            "keyRaw": "name",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "price": {
                                                                "type": "MoneyV2",
                                                                "keyRaw": "price",

                                                                "selection": {
                                                                    "fields": {
                                                                        "amount": {
                                                                            "type": "Decimal",
                                                                            "keyRaw": "amount",
                                                                            "visible": true
                                                                        },

                                                                        "currencyCode": {
                                                                            "type": "CurrencyCode",
                                                                            "keyRaw": "currencyCode",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "compareAtPrice": {
                                                                "type": "MoneyV2",
                                                                "keyRaw": "compareAtPrice",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "amount": {
                                                                            "type": "Decimal",
                                                                            "keyRaw": "amount",
                                                                            "visible": true
                                                                        },

                                                                        "currencyCode": {
                                                                            "type": "CurrencyCode",
                                                                            "keyRaw": "currencyCode",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "imageUrls": {
                                                                "type": "Metafield",
                                                                "keyRaw": "imageUrls(key: \"image_urls\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "sku": {
                                                                "type": "Metafield",
                                                                "keyRaw": "sku(key: \"just_sku\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "quantityAvailable": {
                                                                "type": "Int",
                                                                "keyRaw": "quantityAvailable",
                                                                "nullable": true,
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "ProductCardFragment": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "firstHandle": "String",
            "secondHandle": "String"
        },

        "types": {}
    },

    "policy": "CacheOrNetwork",
    "partial": false
};

"HoudiniHash=13ca3f54c0a9d2b416a5bc9138875013c3c1a0d7a6c5f8cabe5215d320176ce3";