<script lang="ts">
	import type { ContentBlock13Props } from '$lib/storyblok/types';
	import { storyblokEditable } from '@storyblok/svelte';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import RichText from '$lib/components/Storyblok/RichText.svelte';
	import StoryblokImageOrVideo from '$lib/components/Image/StoryblokImageOrVideo.svelte';
	import { getImagePriorityBelowFold } from '$lib/utils/image';

	export let blok: ContentBlock13Props;
	export let position: number = 0;
	$: isBelowFold = position > 0;
</script>

<div
	use:storyblokEditable={blok}
	class={classes(
		'flex relative flex-col col-end md:row-end sm:min-w-fit md:min-w-full md:max-h-[775px] py-11 md:py-16',
		blok.imageLeft ? 'md:flex-row-reverse' : 'md:flex-row'
	)}
>
	<div
		style:background-color={`var(${
			blok.content_branch ? `--special-${blok.content_branch}` : '--accent-primary'
		})`}
		class="flex-1 flex flex-col justify-center pl-3 pr-3 pb-12 sm:pl-5 sm:pr-20"
	>
		{#if blok.title}
			<div
				class={classes(
					'min-w-fit text-h-40 md:text-h-56 xl:text-h-72 text-white mt-14 sm:mt-20 tracking-wide',
					blok.split_headline ? 'hyphens-auto' : ''
				)}
			>
				{blok.title}
			</div>
		{/if}
		<div
			class="mt-10 text-white text-p-16 !font-extralight !md:leading-9 md:text-p-24 md:mt-6 md:max-w-[412px]"
		>
			<RichText text={blok.textContent} />
		</div>
	</div>
	{#if blok.image?.filename || blok.video_id || blok.shopTheLook}
		<StoryblokImageOrVideo
			class="w-full max-w-[1400px] aspect-video"
			filename={blok.image?.filename}
			placeholder="blur"
			focus={blok.image?.focus}
			alt={blok.image?.alt}
			parallax={blok.image_parallax}
			layout="intrinsic"
			width={1400}
			height={1400}
			objectFit="cover"
			video_id={blok.video_id}
			autoplay={blok.autoplay}
			shopTheLook={blok.shopTheLook}
			title={blok.image?.title}
			{...getImagePriorityBelowFold(isBelowFold)}
		/>
	{/if}
</div>
