<script lang="ts">
	import { page } from '$app/stores';
	import { storyblokEditable } from '@storyblok/svelte';
	import { browser } from '$app/environment';
	import RichText from '$lib/components/Storyblok/RichText.svelte';

	$: blok = $page.data?.discountBar?.content;

	let dismissed = false;
	const LAST_DISCOUNT_UID = 'lastDiscountUID';

	$: if (blok && browser && !dismissed) {
		if (
			`${blok._uid}-${$page.data.discountBar.published_at}` ===
			localStorage.getItem(LAST_DISCOUNT_UID)
		) {
			dismissed = true;
		}
	}
</script>

{#if blok && !dismissed}
	<div
		use:storyblokEditable={blok}
		class="w-full min-h-[42px] bg-special-dog text-white relative flex px-4 gap-1 items-center justify-between"
	>
		<div class="w-4 h-4" />
		<div class="grid place-items-center h-full text-center">
			<RichText text={blok.content} />
		</div>
		<button
			data-testid="__nav-discount-banner-dismiss"
			on:click={() => {
				dismissed = true;
				localStorage.setItem(
					LAST_DISCOUNT_UID,
					`${blok._uid}-${$page.data.discountBar.published_at}`
				);
			}}
			class="flex-0 i-sprenger-x w-4 h-4 text-white"
		/>
	</div>
{/if}
