<script lang="ts">
	// import type { AccordionBlockProps } from '$lib/storyblok/types';
	import { onMount, onDestroy } from 'svelte';
	import { storyblokEditable } from '@storyblok/svelte';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { browser } from '$app/environment';
	import { T } from '@tolgee/svelte';

	import AccordionCard from '$lib/components/Card/AccordionCard.svelte';

	export let blok;
	let activeAccordion: string = '';
	let tabsElement: HTMLUListElement;

	const toggleAccordion = (id: string) => {
		activeAccordion = activeAccordion === id ? '' : id;

		if (activeAccordion === id && tabsElement) {
			const offsetTop = tabsElement.getBoundingClientRect().top + window.scrollY - 270;
			window.scrollTo({
				top: offsetTop,
				behavior: 'smooth'
			});
		}
	};
</script>

<section
	class={classes(
		'w-full overflow-x-hidden pt-10 lg:pt-20 pb-20',
		activeAccordion ? 'lg:pb-3' : 'lg:pb-20'
	)}
>
	<div class="box-x-wrapper flex" use:storyblokEditable={blok}>
		<div class="flex flex-row gap-5 w-full">
			<!-- Tabs -->
			<ul bind:this={tabsElement} class="flex flex-col w-full flex-1">
				{#each blok.accordionCards as accordionCard}
					<li class="flex lg:gap-5">
						<button
							on:click={() => toggleAccordion(accordionCard._uid)}
							class="flex items-center justify-between gap-4 border-b-2 border-black flex-shrink-0 w-full lg:w-130 text-start py-4.5 lg:py-7"
						>
							<div class="text-p-20">{accordionCard.title}</div>
							{#if activeAccordion === accordionCard._uid}
								<div class="i-sprenger-minus w-5 h-5 shrink-0" />
							{:else}
								<div class="i-sprenger-plus w-5 h-5 shrink-0" />
							{/if}
						</button>
					</li>
				{/each}
			</ul>
			<!-- Content -->
			<div
				class="absolute top-0 left-0 z-10 lg:z-0 bg-white lg:relative flex flex-col w-full px-5 lg:pl-6 gap-20"
			>
				{#if activeAccordion}
					<button
						on:click={() => toggleAccordion('')}
						class="flex lg:hidden items-center justify-between w-full"
					>
						<div class="i-sprenger-chevron-left w-5 h-5" />
						<T keyName="accordionBlock.active.backButton" />
					</button>
				{/if}
				{#each blok.accordionCards as accordionCard}
					{#if activeAccordion === accordionCard._uid}
						<AccordionCard blok={accordionCard} />
					{/if}
				{/each}
			</div>
		</div>
	</div>
</section>

<style>
	:global(.rich-text h3, .rich-text h4, .rich-text h5, .rich-text h6) {
		@apply mt-7.5 mb-5;
	}
	:global(.rich-text a) {
		@apply underline;
	}
</style>
