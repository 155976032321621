<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';
	import StoryblokButton from '$lib/components/Button/StoryblokButton.svelte';
	import type { ImageStoryblok } from '$lib/storyblok/types';
	import Image from '$lib/components/Image/Image.svelte';
	import { createBreakpointStore } from '$lib/store/breakpoint.store';
	import { getImagePriorityBelowFold } from '$lib/utils/image';
	import StoryblokImageOrVideo from '$lib/components/Image/StoryblokImageOrVideo.svelte';

	interface EditorialSectionNineProps {
		className?: string;
		image?: ImageStoryblok & {
			dimensions: {
				width: number;
				height: number;
			};
		};
		no_mobile_padding?: boolean;
		mobile_image?: ImageStoryblok & {
			dimensions: {
				width: number;
				height: number;
			};
		};
		button?: any; // ButtonProps;
	}

	export let blok: EditorialSectionNineProps;
	export let className: string;
	export let position: number = 0;
	$: isBelowFold = position > 0;

	$: image = blok.image;
	$: button = blok.button;
	$: mobile_image = blok.mobile_image;
	$: no_mobile_padding = blok.no_mobile_padding;
	const mobileStore = createBreakpointStore();
	$: isMobile = $mobileStore;
</script>

<div
	class={classes(
		'bg-accent-primary_005 py-11 md:py-16',
		className,
		no_mobile_padding || (!isMobile && !button)
			? ''
			: !isMobile
				? 'pb-16'
				: button
					? 'pb-16'
					: 'py-16'
	)}
>
	<div
		class={classes(
			no_mobile_padding ? '' : 'box-x-wrapper',
			'flex flex-col items-center space-y-8 lg:space-y-16 lg:max-h-160 lg:aspect-video'
		)}
	>
		<div class={classes(no_mobile_padding ? '' : 'px-4', 'relative w-full sm:w-2/3')}>
			{#if (!isMobile || !mobile_image) && !!image?.filename}
				<Image
					src={image.filename}
					focus={image.focus}
					alt={image.alt}
					layout="responsive"
					width={image.dimensions?.width}
					height={image.dimensions?.height}
					class="mx-auto"
					title={image.title}
					{...getImagePriorityBelowFold(isBelowFold)}
				/>
			{/if}
			{#if isMobile && !!mobile_image?.filename}
				<Image
					src={mobile_image.filename}
					focus={mobile_image.focus}
					alt={mobile_image.alt}
					layout="responsive"
					width={mobile_image.dimensions?.width}
					height={mobile_image.dimensions?.height}
					class="mx-auto"
					title={mobile_image.title}
					{...getImagePriorityBelowFold(isBelowFold)}
				/>
			{/if}
		</div>

		{#if button}
			<StoryblokButton {...button} />
		{/if}
	</div>
</div>
