<script lang="ts">
	import { storyblokEditable } from '@storyblok/svelte';
	import type { ContentBlock3Props } from '$lib/storyblok/types';
	import InformationComponent from './InformationComponent.svelte';
	import { classes } from '@thearc-hq/ui-kit/utils';

	export let blok: ContentBlock3Props;
</script>

<div
	use:storyblokEditable={blok}
	class={classes(blok.blue_background ? 'bg-accent-primary_005' : '')}
>
	<div
		class={classes(
			'flex flex-col-reverse w-full space-y-6 space-y-reverse md:space-y-8 md:flex-col box-x-wrapper py-11 md:py-16'
		)}
	>
		{#if blok.text_emphasis === 'right'}
			<div class="flex flex-row justify-end w-full h-full z-0">
				<InformationComponent {blok} />
			</div>
		{/if}
		{#if blok.text_emphasis === 'left'}
			<div class="flex flex-row justify-start w-full h-full z-0">
				<InformationComponent {blok} />
			</div>
		{/if}
		{#if blok.text_emphasis === 'center'}
			<div class="flex flex-row justify-center w-full h-full z-0">
				<InformationComponent {blok} />
			</div>
		{/if}
	</div>
</div>
