<script lang="ts">
	import { getTranslate } from '@tolgee/svelte';
	import { SUPPORTED_LANGUAGES } from '$lib/i18n/constants';
	import { currencyCodeToSymbol, languageCodeToCountryCode } from '$lib/utils/i18n';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { languageStore } from '$lib/store/language.store';
	import { shippingMarkets } from '$lib/utils/shippingCountries';
	import { languageHelperStore, PickerState } from '$lib/store/languageHelper.store';

	const onClose = () => {
		languageHelperStore.setPickerState(PickerState.DONE);
	};
	const onSelected = () => {
		languageHelperStore.setPickerState(PickerState.FINISH);
	};

	const { t } = getTranslate();
</script>

{#if $languageHelperStore.state === PickerState.CHOOSE_COUNTRY}
	<div
		class="fixed z-[60] left-1/2 top-1/2 transform bg-white -translate-x-1/2 -translate-y-1/2 flex flex-col w-full h-full md:h-auto md:max-h-[90%] md:w-[413px] sm:max-w-[95%]"
	>
		<button
			on:click={() => onClose()}
			class="ml-auto w-10 h-10 text-accent-primary mr-4 mt-6 i-sprenger-x-circle"
		>
		</button>
		<div class="px-4 mt-6 overflow-y-auto">
			<div class="text-p-24">{$t('languagePicker.title')}</div>
			<div class="flex flex-col gap-4 my-8">
				{#each SUPPORTED_LANGUAGES as e}
					{@const iconFromCode = languageCodeToCountryCode(e)}
					<button
						on:click={() => {
							languageStore.changeLocale(e.toLowerCase());
						}}
						class={classes(
							'flex justify-between hover:bg-accent-primary_005 py-2 px-1',
							e === $languageStore.locale ? 'bg-accent-primary_005' : ''
						)}
					>
						<div class="flex items-center gap-2">
							<div class={`i-flag-${iconFromCode} w-6 h-7`}></div>
							{$t(`languagePicker.languages.${e}`)}
						</div>
					</button>
				{/each}
			</div>

			<div class="text-p-24">{$t('country.select.title')}</div>
			<div class="text-p-16 text-dark-tertiary">{$t('country.select.subtitle')}</div>
			<div class="flex flex-col gap-4 my-8">
				{#each shippingMarkets as e}
					{@const iconFromCode = e.regions.nodes[0].code.toLowerCase()}
					<button
						on:click={() => {
							languageStore.changeCountry(e.regions.nodes[0].code.toUpperCase());
							onSelected();
						}}
						class="flex justify-between hover:bg-dark-quaternary py-2 px-1"
					>
						<div class="flex items-center gap-2">
							<div class={`i-flag-${iconFromCode} w-6 h-7`}></div>
							{$t(`country.${e.regions.nodes[0].code}`)}
						</div>

						<div>
							{currencyCodeToSymbol(e.currencySettings.baseCurrency.currencyCode)}{' '}
							{e.currencySettings.baseCurrency.currencyCode}
						</div>
					</button>
				{/each}
			</div>
		</div>
	</div>
	<div
		on:click={() => onClose()}
		on:keydown={(e) => {
			if (e.key === 'Escape') {
				onClose();
			}
		}}
		role="button"
		tabindex="0"
		class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-25 z-50"
	></div>
{/if}
