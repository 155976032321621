<script lang="ts">
	import type { HeroBlockProps } from '$lib/storyblok/types';
	import { navBarSize } from '$lib/store/navBarSize.store';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import Image from '$lib/components/Image/Image.svelte';
	import { primaryColorDict, secondaryColorDict, signatureDictGeneric } from '$lib/color';
	import { storyblokEditable } from '@storyblok/svelte';
	import RichText from '$lib/components/Storyblok/RichText.svelte';
	import StoryblokButton from '$lib/components/Button/StoryblokButton.svelte';
	import { createBreakpointStore } from '$lib/store/breakpoint.store';
	import { getImagePriorityBelowFold } from '$lib/utils/image';

	export let blok: HeroBlockProps;
	$: slideColors = {
		primary: primaryColorDict[blok.content_branch] || primaryColorDict.dog,
		secondary: secondaryColorDict[blok.content_branch] || secondaryColorDict.dog
	};

	const getFontSize = (text: string) => {
		const viewWidth = typeof window === 'undefined' ? 1440 : window.innerWidth;
		const sectionWidth = viewWidth >= 640 ? viewWidth / 2 - viewWidth * 0.05 : viewWidth - 32;
		const percentageOfViewport = sectionWidth / viewWidth;

		const maxFontSize = 9.5;
		const minFontSize = 1.5;

		const longestWord = Math.max(...text.split(' ').map((l) => l.length));
		const textLimit = 30;

		const textSizeFactor = 1 - Math.max(0.16, longestWord / textLimit) + 0.16;

		const fontSize = `${textSizeFactor * percentageOfViewport * 26}vw`;
		return `clamp(${minFontSize}rem, ${maxFontSize}rem, ${fontSize})`;
	};

	const mobileStore = createBreakpointStore();
	$: isMobile = $mobileStore;
	$: titleSize = getFontSize(blok.headline);
</script>

<div
	use:storyblokEditable={blok}
	class="hero-slider grid relative"
	style:height={isMobile ? '' : `calc(100vh - ${$navBarSize}px)`}
>
	<div class="image-gap relative hidden md:block">
		<Image
			{...getImagePriorityBelowFold(false)}
			src={blok.bg_image.filename}
			class={classes('h-full w-full object-cover object-left absolute')}
			sizes="1vw"
		/>
	</div>
	<div class="text min-h-md md:min-h-auto relative bg-white">
		<div
			class="absolute w-full flex flex-col md:justify-between items-center h-full left-1/2 transform -translate-x-1/2"
		>
			<div></div>
			<h1
				style:font-size={titleSize}
				style:line-height="100%"
				class="text-h-72 my-16 md:text-h-126 lg-after:text-h-152 break-words max-w-xs md:max-w-2xl mx-auto text-center"
			>
				{blok.headline}
			</h1>
			<!--
                <a href={slide.href.url || `${slide.href.cached_url}`}>
                    <ButtonPrimary text={slide.call_to_action} class="my-10" />
                </a> -->
			<div
				class={`${
					signatureDictGeneric[blok.content_branch || 'sprenger']
				} text-black w-32 lg:w-48 lg:h-32 h-24`}
			></div>
		</div>
	</div>
	<div class="big-image relative">
		<Image
			{...getImagePriorityBelowFold(false)}
			src={blok.bg_image.filename}
			alt={blok.bg_image.alt}
			title={blok.bg_image.title}
			class={classes('h-full w-full object-cover absolute')}
			sizes="50vw"
		/>
	</div>
	<div class="secondary-gap" style:background-color={slideColors.secondary}></div>
	<div
		class="secondary-gap-long text-accent-primary"
		style:background-color={slideColors.secondary}
	>
		<div class="grid place-items-center w-full">
			<div
				class="flex flex-col w-full md:w-[328px] max-w-xl items-center px-4 space-y-6 sm:items-start sm:px-0 text-accent-primary py-5 sm:py-10"
			>
				<div class="space-y-2">
					<p class="uppercase font-extrabold text-h-16">{blok.title}</p>
					<RichText text={blok.description} />
					{#if blok.button_link && blok.button_text}
						<StoryblokButton text={blok.button_text} url={blok.button_link.cached_url} />
					{/if}
				</div>
			</div>
		</div>
	</div>
	<div class="primary-gap" style:background-color={slideColors.primary}></div>
</div>

<style>
	.hero-slider {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1rem 1.5rem 18rem fit-content(100%);
		grid-template-areas: 'text' 'primary-gap' 'secondary-gap' 'big-image' 'secondary-gap-long';
	}

	@media screen and (min-width: 768px) {
		.hero-slider {
			grid-template-columns: 1.5% 48.5% 50%;
			/*
			TODO: This Layout is broken an scales over 100% with 1fr, needs fix
			*/
			grid-template-rows: 1fr auto 1.5rem;
			grid-template-areas:
				'image-gap text big-image'
				'secondary-gap text secondary-gap-long'
				'primary-gap primary-gap primary-gap';
		}
	}

	.image-gap {
		grid-area: image-gap;
	}

	.text {
		grid-area: text;
	}

	.big-image {
		grid-area: big-image;
	}

	.secondary-gap {
		grid-area: secondary-gap;
	}
	.secondary-gap-long {
		grid-area: secondary-gap-long;
	}

	.primary-gap {
		grid-area: primary-gap;
	}
</style>
