import { collectionSideDrawer } from './collectionSideDrawer.store';
import { derived, writable } from 'svelte/store';
import { createBreakpointStore } from '$lib/store/breakpoint.store';

const createScrollNavBarHandler = () => {
	const scrollY = writable(0);
	const isColorfulBar = derived(
		[scrollY, collectionSideDrawer, createBreakpointStore()],
		([$scrollY, $collectionSideDrawer, $isMobile]) =>
			$scrollY > 0 || ($collectionSideDrawer.isExpanded && $isMobile)
	);
	const scrollNavBarVisible = writable(true);
	let delta = 0;
	let startPosition = 0;
	const SCROLL_THRESHOLD = 15;

	const data = derived(
		[scrollY, isColorfulBar, scrollNavBarVisible],
		([$scrollY, $isColorfulBar, $scrollNavBarVisible]) => {
			return {
				scrollY: $scrollY,
				isColorfulBar: $isColorfulBar,
				scrollNavBarVisible: $scrollNavBarVisible
			};
		}
	);

	return {
		...data,
		setScrollY: (value: number) => {
			delta = value - startPosition;
			startPosition = value;
			scrollY.set(value);

			if (delta > SCROLL_THRESHOLD) {
				scrollNavBarVisible.set(false);
			} else if (delta < -SCROLL_THRESHOLD) {
				scrollNavBarVisible.set(true);
			}
		}
	};
};

export const scrollNavBarHandler = createScrollNavBarHandler();
